import {all, fork, put, takeEvery, call} from 'redux-saga/effects';
import {showGenericModal} from "../layout/actions";
import {AM_webServicePUT} from "../../scripts/api";
import {setUserLang} from "./actions";

// Update member language preferences whenever language is changed
function* callLangsWebService({payload: lang}) {
        try {
            const url = '/member/languagepreference';
            const response = yield call(AM_webServicePUT, url, {language: lang});
            if (response.data) {
                yield put(setUserLang(lang))
            }
        } catch (e) {
            yield put(showGenericModal())
        }

}

function* callLangChangeRequested({payload: lang}) {
    try {
        yield put(setUserLang(lang))
    } catch (e) {
        yield put(showGenericModal())
    }
}

function* watchCallLangsWebService() {
    yield takeEvery("@@langs/MEMBER_LANG_CHANGE_REQUESTED", callLangsWebService)
}

function* watchPublicLangChangeRequesteed() {
    yield takeEvery("@@langs/PUBLIC_LANG_CHANGE_REQUESTED", callLangChangeRequested)
}

export default function* langsSaga() {
    yield all([
        fork(watchCallLangsWebService),
        fork(watchPublicLangChangeRequesteed)
    ]);
}