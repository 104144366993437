// @flow
import { AuthActionTypes } from './constants';
import * as api from '../../scripts/api';
import {AM_addressString} from "../../scripts/formatting";
import {AM_convertProfileInfotoUserInfo, AM_mergeHomeWorkLocations} from "../../scripts/helpers";

const AUTH_SESSION_KEY = 'AM_user';
const INIT_STATE = {
    user: getUser(),
    loading: false,
};

function getUser() {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
}

type AuthAction = { type: string, payload: { actionType?: string, data?: any, error?: string } };
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Auth = (state: State = INIT_STATE, action: AuthAction): any => {
    
    switch (action.type) {
        case AuthActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        user: action.payload.data,
                        userLoggedIn: true,
                        loading: false,
                    };
                }
                case AuthActionTypes.SIGNUP_USER: {
                    return {
                        ...state,
                        user: action.payload.data,
                        loading: false,
                        userSignUp: true,
                    };
                }
                case AuthActionTypes.LOGOUT_USER: {
                    return {
                        ...state,
                        user: null,
                        loading: false,
                        userLogout: true,
                        userLoggedIn: false,
                        userSignUp: false
                    };
                }
                case AuthActionTypes.DELETE_USER: {
                    return {
                        ...state,
                        user: null,
                        loading: false,
                        userDeleted: true,
                        userLoggedIn: false,
                        userSignUp: false
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        resetPasswordSuccess: action.payload.data,
                        loading: false,
                        passwordReset: true,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD_CHANGE: {
                    return {
                        ...state,
                        loading: false,
                        passwordChange: true,
                    };
                }
                default:
                    return { ...state };
            }

        case AuthActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        userLoggedIn: false,
                        loading: false,
                        user: null
                    };
                }
                case AuthActionTypes.LOGOUT_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        userLogout: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.DELETE_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        userDeleted: false,
                    };
                }
                case AuthActionTypes.SIGNUP_USER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        userSignUp: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        passwordReset: false,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD_CHANGE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        passwordChange: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AuthActionTypes.LOGIN_USER:
            return { ...state, loading: true, userLoggedIn: false };
        case AuthActionTypes.LOGOUT_USER:
            return { ...state, loading: true, userLogout: false };
        case AuthActionTypes.SIGNUP_USER:
            return { ...state, loading: true, userSignUp: false };
        case AuthActionTypes.DELETE_USER:
            return { ...state, loading: true};
        case AuthActionTypes.DELETE_USER_COMPLETE:
            return { ...state, userDeleted: false};
        case AuthActionTypes.FORGOT_PASSWORD:
            return { ...state, loading: true, passwordReset: false, error: false };
        case AuthActionTypes.FORGOT_PASSWORD_CHANGE:
            return { ...state, loading: true, passwordChange: false };
        case AuthActionTypes.UPDATE_PHOTO:
            return { ...state, user: {...state.user, photo: action.payload.photo}};
        case AuthActionTypes.UPDATE_PASSWORD:
            return { ...state, user: {...state.user, token: action.payload.token, password: action.payload.password, password_expired: false}};
        case AuthActionTypes.UPDATE_HOME:
            return { ...state, user: {...state.user, live_address: AM_addressString(action.payload.location), live_lat: action.payload.location.lat, live_lng: action.payload.location.lng, locations: AM_mergeHomeWorkLocations(action.payload.location, state.user.locations)}};
        case AuthActionTypes.UPDATE_WORK:
            return { ...state, user: {...state.user, commute_address: AM_addressString(action.payload.location), commute_lat: action.payload.location.lat, commute_lng: action.payload.location.lng, locations: AM_mergeHomeWorkLocations(action.payload.location, state.user.locations)}};
        case AuthActionTypes.UPDATE_LOCATIONS:
            return { ...state, user: {...state.user, locations: action.payload.locations}};
        case AuthActionTypes.UPDATE_EMAILS:
            return { ...state, user: {...state.user, emails: action.payload.emails}};
        case AuthActionTypes.UPDATE_PROFILE:
            return { ...state, user: {...state.user, ...AM_convertProfileInfotoUserInfo(action.payload.profileInfo, state.user.locations, action.payload.needsVerification)}};
        case AuthActionTypes.UPDATE_EMAIL_VERIFY:
            return { ...state, user: {...state.user, verify_email: !action.payload.isVerified}};
        case AuthActionTypes.RESET:
            return {
                ...state,
                registerError: false,
                loading: false,
                error: false,
                userSignUp: false,
                userLoggedIn: false,
                passwordReset: false,
                passwordChange: false,
                resetPasswordSuccess: null,
            };
        default:
            return { ...state };
    }
};

export default Auth;
