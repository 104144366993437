// @flow
import { LayoutActionTypes } from './constants';

import * as layoutConstants from '../../constants/layout';

const INIT_STATE = {
    theme: layoutConstants.THEME_LIGHT,
    layoutType: layoutConstants.LAYOUT_VERTICAL,
    layoutWidth: layoutConstants.LAYOUT_WIDTH_FLUID,
    leftSideBarTheme: layoutConstants.LEFT_SIDEBAR_THEME_DARK,
    leftSideBarType: layoutConstants.LEFT_SIDEBAR_TYPE_FIXED,
    showRightSidebar: false,
    showGenericModal: false,
    genericModalTitle: "",
    genericModalBody: "",
    genericModalTheme: "",
    genericModalHomeButton: false,
};

type LayoutAction = { type: string, payload?: string | null };

type State = { showRightSidebar?: boolean, +value?: boolean };

const Layout = (state: State = INIT_STATE, action: LayoutAction): any => {
    switch (action.type) {
        case LayoutActionTypes.CHANGE_THEME:
            return {
                ...state,
                theme: action.payload,
            }
        case LayoutActionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layoutType: action.payload,
            };
        case LayoutActionTypes.CHANGE_LAYOUT_WIDTH:
            return {
                ...state,
                layoutWidth: action.payload,
            };
        case LayoutActionTypes.CHANGE_SIDEBAR_THEME:
            return {
                ...state,
                leftSideBarTheme: action.payload,
            };
        case LayoutActionTypes.CHANGE_SIDEBAR_TYPE:
            return {
                ...state,
                leftSideBarType: action.payload,
            };
        case LayoutActionTypes.TOGGLE_RIGHT_SIDEBAR:
            return {
                ...state,
                showRightSidebar: !state.showRightSidebar,
            };
        case LayoutActionTypes.SHOW_RIGHT_SIDEBAR:
            return {
                ...state,
                showRightSidebar: true,
            };
        case LayoutActionTypes.HIDE_RIGHT_SIDEBAR:
            return {
                ...state,
                showRightSidebar: false,
            };
        case LayoutActionTypes.SHOW_GENERIC_MODAL:
            return {
                ...state,
                showGenericModal: true,
                genericModalTitle:  action.payload ? action.payload.title : "",
                genericModalBody: action.payload ? action.payload.body : "",
                genericModalTheme: action.payload ? action.payload.theme : "",
                genericModalHomeButton: action.payload ? action.payload.showHomeButton : false
            };
        case LayoutActionTypes.HIDE_GENERIC_MODAL:
            return {
                ...state,
                showGenericModal: false,
            };
        default:
            return state;
    }
};

export default Layout;
