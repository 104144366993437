import { all, fork, put, takeEvery, call, select } from 'redux-saga/effects';

import {
    login as loginApi,
    logout as logoutApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
    forgotPasswordConfirm,
} from '../../helpers/';

import { authApiResponseSuccess, authApiResponseError } from './actions';
import { AuthActionTypes } from './constants';
import * as api from "../../scripts/api";
import history from "../../history";
import {AM_setTokenToStorage, AM_updateUserInSession} from "../../scripts/api";
import {AM_addressString} from "../../scripts/formatting";
import {AM_convertProfileInfotoUserInfo, AM_getURLParameter, AM_mergeHomeWorkLocations} from "../../scripts/helpers";
import {loginFailureLog} from "../../helpers/api/auth";
import {
    PASSWORD_RESET_ACCOUNT_LOCKED_ERROR,
    PASSWORD_RESET_INVALID_EMAIL_ERROR,
    PASSWORD_RESET_OTHER_ERROR
} from "../../scripts/constants";
import {updateMessageCenter} from "../messageCenter/actions";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { email, password, remember, token } }) {
    try {

        // const args = (token ? {token} : {email, password})
        //for real web service, only pass email and password as parameters, token is set in header instead
        const args = ((email && password) ? {email, password} : null);
        if (token) api.AM_setAuthorization(token);
        const response = yield call(loginApi, args);
        const user = response.data;
        //check member belongs to this region
        const currentRegion = yield select(state => state.Brand);
        const memberRegion = user.current_region
        if (currentRegion.id === memberRegion.id) {
            api.AM_setLoggedInUser(user);
            api.AM_setAuthorization(user['token']);
            if (remember) api.AM_setTokenToStorage(user['token']);
            yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
        } else {
            api.AM_setLoggedInUser(null);
            api.AM_setAuthorization(null);
            api.AM_setTokenToStorage(null);
            yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, {code: 410, region: user.current_region}));
        }
        if (Number(AM_getURLParameter("impersonate", -1)) === 1) {
            history.push(history.location.pathname);
        }
    } catch (error) {
        api.AM_setLoggedInUser(null);
        api.AM_setAuthorization(null);
        api.AM_setTokenToStorage(null);
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, {code: error}));
        yield call (loginFailureLog, {error, email, password, token})
        if (Number(AM_getURLParameter("impersonate", -1)) === 1) {
            history.push(history.location.pathname);
        }
    }
}

/**
 * Logout the user
 */
function* logout() {
    try {
        yield call(logoutApi);
        api.AM_setLoggedInUser(null);
        api.AM_setAuthorization(null);
        api.AM_setTokenToStorage(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
        yield put(updateMessageCenter(null));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* deleteUser() {
    try {
        api.AM_setLoggedInUser(null);
        api.AM_setAuthorization(null);
        api.AM_setTokenToStorage(null);
        yield put(authApiResponseSuccess(AuthActionTypes.DELETE_USER, {}));
        history.push('/account/deleted');
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.DELETE_USER, error));
    }
}

function* signup({ payload: { firstname, lastname, email, password, region_id, partner_org_id, partner_org_affiliation_id } }) {
    try {
        const response = yield call(signupApi, { firstname, lastname, email, password, region_id, partner_org_id, partner_org_affiliation_id });
        const user = response.data;
        api.AM_setLoggedInUser(user);
        api.AM_setAuthorization(user['token']);
        api.AM_setTokenToStorage(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        api.AM_setLoggedInUser(null);
        api.AM_setAuthorization(null);
        api.AM_setTokenToStorage(null);
    }
}

function* forgotPassword({ payload: { email } }) {
    try {
        const response = yield call(forgotPasswordApi, { email });
        if (response.data.sentOk) {
            yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
        } else {
            let error = PASSWORD_RESET_OTHER_ERROR;
            if (response.data.accountLocked) error = PASSWORD_RESET_ACCOUNT_LOCKED_ERROR;
            if (!response.data.validAddress) error = PASSWORD_RESET_INVALID_EMAIL_ERROR;
            yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
        }
    } catch (error) {
        let errorToPass = PASSWORD_RESET_OTHER_ERROR;
        if (error?.data?.accountLocked) errorToPass = PASSWORD_RESET_ACCOUNT_LOCKED_ERROR;
        if (error?.data?.validAddress === false) errorToPass = PASSWORD_RESET_INVALID_EMAIL_ERROR;
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, errorToPass));
    }
}

function* forgotPasswordChange({ payload: { data } }) {
    try {
        const response = yield call(forgotPasswordConfirm, data);
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD_CHANGE, response.data));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error));
    }
}

function* updatePhotoInfo({ payload: {photo} }) {
    yield call(AM_updateUserInSession, {photo : photo});
}

function* updatePasswordInfo({ payload: {password, token} }) {
    yield call(AM_setTokenToStorage, token);
    yield call(AM_updateUserInSession, {token : token, password: password, password_expired: false});
}

function* updateHomeInfo({ payload: {location} }) {
    const homeInfo = {
        live_lat: location.lat,
        live_lng: location.lng,
        live_address: AM_addressString(location),
        locations : AM_mergeHomeWorkLocations(location, yield select(state => state.Auth.user.locations))
    }
    yield call(AM_updateUserInSession, homeInfo);
}

function* updateWorkInfo({ payload: {location} }) {
    const workInfo = {
        commute_lat: location.lat,
        commute_lng: location.lng,
        commute_address: AM_addressString(location),
        locations : AM_mergeHomeWorkLocations(location, yield select(state => state.Auth.user.locations))
    }
    yield call(AM_updateUserInSession, workInfo);
}

function* updateLocationInfo({ payload: {locations} }) {
    const locationInfo = {
        locations: locations
    }
    yield call(AM_updateUserInSession, locationInfo);
}

function* updateEmailInfo({ payload: {emails} }) {
    const emailInfo = {
        emails: emails
    }
    yield call(AM_updateUserInSession, emailInfo);
}

function* updateProfileInfo({ payload: {profileInfo, needsVerification} }) {
    const profileDataToUpdate = AM_convertProfileInfotoUserInfo(profileInfo, yield select(state => state.Auth.user.locations), needsVerification);
    yield call(AM_updateUserInSession, profileDataToUpdate);
}

function* updateEmailVerify({ payload: {isVerified} }) {
    const verifyInfo = {verify_email: !isVerified}
    yield call(AM_updateUserInSession, verifyInfo);
}

export function* watchLoginUser(): any {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout(): any {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchForgotPasswordChange(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, forgotPasswordChange);
}

export function* watchDeleteUser(): any {
    yield takeEvery(AuthActionTypes.DELETE_USER, deleteUser);
}

export function* watchUpdatePhoto(): any {
    yield takeEvery(AuthActionTypes.UPDATE_PHOTO, updatePhotoInfo);
}

export function* watchUpdatePassword(): any {
    yield takeEvery(AuthActionTypes.UPDATE_PASSWORD, updatePasswordInfo);
}

export function* watchUpdateHome(): any {
    yield takeEvery(AuthActionTypes.UPDATE_HOME, updateHomeInfo);
}

export function* watchUpdateWork(): any {
    yield takeEvery(AuthActionTypes.UPDATE_WORK, updateWorkInfo);
}
export function* watchUpdateLocations(): any {
    yield takeEvery(AuthActionTypes.UPDATE_LOCATIONS, updateLocationInfo);
}

export function* watchUpdateEmails(): any {
    yield takeEvery(AuthActionTypes.UPDATE_EMAILS, updateEmailInfo);
}

export function* watchUpdateProfile(): any {
    yield takeEvery(AuthActionTypes.UPDATE_PROFILE, updateProfileInfo);
}

export function* watchUpdateEmailVerify(): any {
    yield takeEvery(AuthActionTypes.UPDATE_EMAIL_VERIFY, updateEmailVerify);
}

function* authSaga(): any {
    yield all([
        fork(watchLoginUser),
        fork(watchLogout),
        fork(watchSignup),
        fork(watchForgotPassword),
        fork(watchForgotPasswordChange),
        fork(watchDeleteUser),
        fork(watchUpdatePhoto),
        fork(watchUpdatePassword),
        fork(watchUpdateHome),
        fork(watchUpdateWork),
        fork(watchUpdateLocations),
        fork(watchUpdateEmails),
        fork(watchUpdateProfile),
        fork(watchUpdateEmailVerify)
    ]);
}

export default authSaga;
