/***********************************************
 *
 *  Navigation functions to go to pages or views
 *
 ***********************************************/

import history from "../history";

const setGlobalFunctions = () => {

    window.AM_gotoStarter = () => {
        history.push('/pages/starter');
    }

    window.AM_gotoLogin = (tpLink) => {
        let path = '/account/login';
        if (tpLink) path+= `?tpLink=${tpLink}`;
        history.push(path);
    }

    window.AM_gotoSignup = (tpLink) => {
        let path = '/account/register';
        if (tpLink) path+= `?tpLink=${tpLink}`;
        history.push(path);
    }

    window.AM_goto404 = () => {
        history.push('/404');
    }

    window.AM_gotoContactUs = () => {
        history.push('/contact_us');
    }

    window.AM_gotoCommuteCalculator = () => {
        history.push('/commute_calculator');
    }

    window.AM_gotoFAQs = () => {
        history.push('/faqs');
    }

    window.AM_gotoLegal = (event, doc) => {
        let path = '/legal';
        if (doc) {
            path += `?doc=${doc}`;
        }
        history.push(path);
    }

    window.AM_gotoHelp = (event,topicId) => {
        let path = '/more/help';
        if (topicId) path+= `?topicID=${topicId}`;
        history.push(path);
    }

    window.AM_gotoAutoRecordedTrips = () => {
        history.push('/trips/auto_record_trips');
    }

    window.AM_gotoAutoRecordedTrip = () => {
        window.AM_gotoAutoRecordedTrips();
    }

    window.AM_gotoCompletedTrips = (event, mode) => {
        let path = '/trips/trips';
        if (mode) path+= `?mode=${mode}`;
        history.push(path);
    }

    window.AM_gotoContactInfo = () => {
        history.push('/account/contact_info');
    }

    window.AM_gotoDeleteAccount = () => {
        history.push('/account/delete_account');
    }

    window.AM_gotoDoNotContactList = () => {
        history.push('/account/do_not_contact');
    }

    window.AM_gotoEditTrip = (event, id) => {
       //not sure what to do with this yet
    }

    window.AM_gotoEditTripProfile = (event, id) => {
        //not sure what to do with this yet
    }

    window.AM_gotoEmails = () => {
        history.push('/account/emails');
    }

    window.AM_gotoLocations = () => {
        history.push('/account/locations');
    }

    window.AM_gotoMessageCenter = () => {
        history.push('/home');
    }

    window.AM_gotoHome = (query) => {
        history.push(`/home${query ? query : ""}`);
    }

    window.AM_gotoPartnerPage = (event, id) => {
        history.push(`/partner?partnerID=${id}`);
    }

    window.AM_gotoPassword = () => {
        history.push(`/account/password`);
    }

    window.AM_gotoPhoto = () => {
        history.push(`/account/photo`);
    }

    window.AM_gotoPoints = (event, table) => {
        history.push(`/trips/points${table ? `?table=${table}` : ""}`);
    }

    window.AM_gotoPrivacyPolicy = () => {
        history.push('/privacy_policy');
    }

    window.AM_gotoProfile = (event, section) => {
        history.push(`/account/profile${section ? `?scrollID=${section}` : ""}`);
    }

    window.AM_gotoRaces = () => {
        history.push(`/account/racing`);
    }

    window.AM_gotoRace = () => {
        window.AM_gotoRaces();
    }

    window.AM_gotoRaceIndividual = (id) => {
        history.push(`/race_individual/${id}`);
    }

    window.AM_gotoRaceTeam = (id) => {
        history.push(`/race_team/${id}`);
    }

    window.AM_gotoRecordTrip = (event, date, profileId, tripId, edit) => {
        let queryString = `?date=${date ? date : 0}`;
        if (profileId) {
            queryString += `&profileID=${profileId}`;
        } else if (tripId) {
            queryString += `&tripID=${tripId}`;
        }

        if (edit) {
            queryString += `&edit=1`;
        }

        history.push(`/trips/trip${queryString}`);
    }

    window.AM_gotoRewards = (event, category) => {
        history.push(`/rewards${category ? `?category=${category}` : ""}`);
    }

    window.AM_gotoRoot = () => {
        history.push('/');
    }

    window.AM_gotoSpecialEvent = (event, id, leaderboard, rules) => {
        let queryString = "";
        if (leaderboard) {
            queryString = "?leaderboard=true";
        } else if (rules) {
            queryString = "?rules=true";
        }

        history.push(`/event/${id}${queryString}`);
    }

    window.AM_gotoSpecialEvents = () => {
        //just go to home page
        window.AM_gotoHome();
    }

    window.AM_gotoSupervisor = () => {
        history.push(`/account/supervisor`);
    }

    window.AM_gotoTransitPasses = () => {
        history.push(`/wallet`);
    }

    window.AM_gotoTripCalendar = (event, date) => {
        history.push(`/trips/calendar${date ? `?date=${date}` : ""}`);
    }

    window.AM_gotoTripPlanner = (event, params) => {
        let query = '';
        if (params) {
            query = "?agile_tp=true" + params;
        }
        history.push(`/find${query}`);
    }

    window.AM_gotoTripProfiles = (e, id, operation) => {
        //id doesn't do anything, so just ignore it
        history.push(`/trips/trip_profiles${operation ? `?operation=${operation}` : ""}`);
    }

    window.AM_gotoTripSyncing = () => {
        history.push(`/trips/syncing`);
    }

    window.AM_gotoVehicles = () => {
        history.push(`/account/vehicles`);
    }

    window.AM_gotoViewReward = (event, id) => {
        history.push(`/rewards?rewardID=${id}`);
    }

    window.AM_gotoViewTrip = () => {
        //not sure yet
    }

    window.AM_gotoViewTripProfile = () => {
        //not sure yet
    }

    window.AM_gotoVolunteer = () => {
        history.push(`/account/volunteer`);
    }

    window.AM_gotoVolunteerRideProfile = (event, id) => {
        history.push(`account/volunteer?profileID=${id}`);
    }

    window.AM_logout = () => {
        history.push(`/account/logout`);
    }


    //Keep NR_ functions for now so they work, once we convert all existing functions (and update Apps) to AM_ prefix we can remove these.


    window.NR_gotoContactUs = () => {
        window.AM_gotoContactUs();
    }

    window.NR_requestLogin = () => {
        window.AM_gotoLogin();
    }

    window.NR_gotoCommuteCalculator = () => {
        window.AM_gotoCommuteCalculator();
    }

    window.NR_gotoFAQs = () => {
        window.AM_gotoFAQs();
    }

    window.NR_gotoLegal = (event, doc) => {
        window.AM_gotoLegal(event, doc);
    }

    window.NR_requestSignup = () => {
        window.AM_gotoSignup();
    }

    window.NR_gotoHelp = (event,topicId) => {
        window.AM_gotoHelp(event, topicId);
    }

    window.NR_gotoAutoRecordedTrip = () => {
        window.AM_gotoAutoRecordedTrips();
    }

    window.NR_gotoAutoRecordedTrips = () => {
        window.AM_gotoAutoRecordedTrips();
    }

    window.NR_gotoCompletedTrips = (event, mode) => {
        window.AM_gotoCompletedTrips(event, mode);
    }

    window.NR_gotoContactInfo = () => {
        window.AM_gotoContactInfo();
    }

    window.NR_gotoDeleteAccount = () => {
        window.AM_gotoDeleteAccount();
    }

    window.NR_gotoDoNotContactList= () => {
        window.AM_gotoDoNotContactList();
    }

    window.NR_gotoEmails = () => {
        window.AM_gotoEmails();
    }

    window.NR_gotoLocations = () => {
        window.AM_gotoLocations();
    }


    window.NR_gotoMessageCenter = () => {
        window.AM_gotoMessageCenter();
    }

    window.NR_gotoPartnerPage = (event, id) => {
        window.AM_gotoPartnerPage(event, id);
    }

    window.NR_gotoPassword = () => {
        window.AM_gotoPassword();
    }

    window.NR_gotoPhoto = () => {
        window.AM_gotoPhoto();
    }

    window.NR_gotoPoints = (event, table) => {
        window.AM_gotoPoints(event, table);
    }

    window.NR_gotoProfile = (event, section) => {
        window.AM_gotoProfile(event, section);
    }

    window.NR_gotoRaces = () => {
        window.AM_gotoRaces();
    }

    window.NR_gotoRace = () => {
        window.AM_gotoRaces();
    }

    window.NR_gotoRaceIndividual = (event, id) => {
        window.AM_gotoRaceIndividual(id);
    }

    window.NR_gotoRaceTeam = (event, id) => {
        window.AM_gotoRaceTeam(id);
    }

    window.NR_gotoRecordTrip = (event, date) => {
        window.AM_gotoRecordTrip(event, date);
    }

    window.NR_gotoRewards = (event, category) => {
        window.AM_gotoRewards(event, category);
    }

    window.NR_gotoSpecialEvent = (event, id, leaderboard, rules) => {
        window.AM_gotoSpecialEvent(event, id, leaderboard, rules);
    }

    window.NR_gotoSpecialEvents = () => {
        window.AM_gotoSpecialEvents();
    }

    window.NR_gotoSupervisor = () => {
        window.AM_gotoSupervisor();
    }

    window.NR_gotoTransitPasses = () => {
        window.AM_gotoTransitPasses();
    }

    window.NR_gotoTripCalendar = (event, date) => {
        window.AM_gotoTripCalendar(event, date);
    }

    window.NR_gotoTripPlanner = (event, params) => {
        window.AM_gotoTripPlanner(event, params);
    }

    window.NR_gotoTripProfiles = (e, id, operation) => {
        window.AM_gotoTripProfiles(e, id, operation);
    }

    window.NR_gotoTripSyncing = () => {
        window.AM_gotoTripSyncing();
    }

    window.NR_gotoVehicles = () => {
        window.AM_gotoVehicles();
    }

    window.NR_gotoViewReward = (event, id) => {
        window.AM_gotoViewReward(event, id);
    }

    window.NR_gotoViewTrip = () => {
        window.AM_gotoViewTrip();
    }

    window.NR_gotoViewTripProfile = () => {
        window.AM_gotoViewTripProfile();
    }

    window.NR_gotoVolunteer = () => {
        window.AM_gotoVolunteer();
    }

    window.NR_gotoVolunteerRideProfile = (event, id) => {
        window.AM_gotoVolunteerRideProfile(event, id);
    }

    window.NR_logout = () => {
        window.AM_logout();
    }

    window.NR_gotoPrivacyPolicy = () => {
        window.AM_gotoPrivacyPolicy();
    }
}

export {setGlobalFunctions}