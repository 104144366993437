/***********************************************
 *
 *  Functions for formatting dates, times, numbers, currency, etc.
 *
 ***********************************************/
import {
    UNIT_TYPE_IMPERIAL,
    LEADERBOARD_METRIC_GREENER_TRIPS,
    LEADERBOARD_METRIC_NEW_MEMBERS,
    LEADERBOARD_METRIC_PARTICIPANTS,
    LEADERBOARD_METRIC_ACTIVE_MEMBERS,
    LEADERBOARD_METRIC_TOTAL_MEMBERS,
    LEADERBOARD_METRIC_CALORIES_BURNED,
    LEADERBOARD_METRIC_TEAM_SPIRIT,
    LEADERBOARD_METRIC_CARPOOL_TRIPS,
    LEADERBOARD_METRIC_RIDESHARE_TRIPS,
    LEADERBOARD_METRIC_VANPOOL_TRIPS,
    LEADERBOARD_METRIC_BIKE_TRIPS,
    LEADERBOARD_METRIC_WALK_TRIPS,
    LEADERBOARD_METRIC_TRANSIT_TRIPS,
    LEADERBOARD_METRIC_MULTIMODE_TRIPS,
    LEADERBOARD_METRIC_TELECOMMUTES,
    LEADERBOARD_METRIC_COMPRESSED_WEEKS,
    LEADERBOARD_METRIC_MILES_NOT_DRIVEN,
    LEADERBOARD_METRIC_REDUCED_CAR_TRIPS,
    LEADERBOARD_METRIC_BROWN_BAGS,
    LEADERBOARD_METRIC_SCOOTER_TRIPS,
    LEADERBOARD_METRIC_GALLONS_GAS_SAVED,
    LEADERBOARD_METRIC_SCOOTER_TRIPS_PCT,
    LEADERBOARD_METRIC_BROWN_BAGS_PCT,
    LEADERBOARD_METRIC_COMPRESSED_WEEKS_PCT,
    LEADERBOARD_METRIC_TELECOMMUTES_PCT,
    LEADERBOARD_METRIC_MULTIMODE_TRIPS_PCT,
    LEADERBOARD_METRIC_TRANSIT_TRIPS_PCT,
    LEADERBOARD_METRIC_WALK_TRIPS_PCT,
    LEADERBOARD_METRIC_BIKE_TRIPS_PCT,
    LEADERBOARD_METRIC_VANPOOL_TRIPS_PCT,
    LEADERBOARD_METRIC_CARPOOL_TRIPS_PCT,
    LEADERBOARD_METRIC_RIDESHARE_TRIPS_PCT,
    LEADERBOARD_METRIC_PART_RATE_REGISTERED,
    LEADERBOARD_METRIC_PART_RATE_EMPLOYEES,
    LEADERBOARD_METRIC_MONEY_SAVED,
    LEADERBOARD_METRIC_EMISSIONS_PREVENTED,
    TIME_ZONES,
    DAYLIGHT_SAVINGS,
    METERS_PER_MILE,
    BENEFIT_GAS,
    BENEFIT_MILES,
    BENEFIT_REWARDS, BENEFIT_SAVINGS, BENEFIT_EMISSIONS, BENEFIT_CALORIES, BENEFIT_POINTS
} from "./constants";

/***********************************************
 *
 *  Converts a JavaScript system date
 *  into "Jan 2013" format
 *
 *  Input:
 *  date = date in JavaScript system format
 *  locale = the locale the user is currently set to (ie. en-US for US english)
 *
 *  Returns date in "Jan 2013" format
 *
 *  @return {string}
 ***********************************************/
const AM_datetoMonAbbreviatedString = (date, locale) => {
    //this now uses built in int.DateTimeFormat js object and should automatically update formating based on locale
    const options = {month: 'short', year: 'numeric'};
    return new Intl.DateTimeFormat(locale, options).format(date)
}

/***********************************************
 *
 *  Converts a JavaScript system date
 *  into "Jan 1, 2013" format
 *
 *  Input:
 *  date = date in JavaScript system format
 *  locale = the locale the user is currently set to (ie. en-US for US english)
 *
 *  Returns date in "Jan 1, 2013
 *
 *  @return {string}
 ***********************************************/
const AM_datetoMonDayYearAbbreviatedString = (date, locale) => {
    const options = {month: 'short', year: 'numeric', day: 'numeric'};
    return new Intl.DateTimeFormat(locale, options).format(date);
}

/***********************************************
 *
 *  Converts a JavaScript system date
 *  into "Jan 1" format
 *
 *  Input:
 *  date = date in JavaScript system format
 *  locale = the locale the user is currently set to (ie. en-US for US english)
 *
 *  Returns date in "Jan 1
 *
 *  @return {string}
 ***********************************************/
const AM_datetoMonDayAbbreviatedString = (date, locale) => {
    const options = {month: 'short', day: 'numeric'};
    return new Intl.DateTimeFormat(locale, options).format(date);
}

/***********************************************
 *
 *  Converts a JavaScript system date
 *  into "January 1" format
 *
 *  Input:
 *  date = date in JavaScript system format
 *  locale = the locale the user is currently set to (ie. en-US for US english)
 *
 *  Returns date in "January 1"
 *
 *  @return {string}
 ***********************************************/
const AM_datetoMonDayString = (date, locale) => {
    const options = {month: 'long', day: 'numeric'};
    return new Intl.DateTimeFormat(locale, options).format(date);
}

/***********************************************
 *
 *  Converts a JavaScript system date
 *  into MM/DD format accounting for
 *  time zone.
 *
 *  Input:
 *  d = date in JavaScript system format
 *  z = time zone id (e.g. 1 = Eastern, etc.)
 *    = -1 to ignore time zone
 *
 *  Returns date in MM/DD format
 *
 *  @return {string}
 ***********************************************/
const AM_datetoMMDD = (d) => {
    //should add time zone offset to this at some point
    return (d.getMonth() + 1 + "/" + d.getDate());
}

/***********************************************
 *
 *  Converts a JavaScript system date
 *  into MM/DD/YY format accounting for
 *  time zone.
 *
 *  Input:
 *  d = date in JavaScript system format
 *  z = time zone id (e.g. 1 = Eastern, etc.)
 *    = -1 to ignore time zone
 *
 *    Returns date in MM/DD/YY format
 *
 *  @return {string}
 ***********************************************/
const AM_datetoMMDDYY = (d) => {
//should add back in time zone stuff at some point
    return (d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear().toString().slice(2, 4));
}

/***********************************************
 *
 *  Offset date in miliseconds based on browser timezone and return javascript date
 *
 *  Input: date = date in miliseconds
 *
 ***********************************************/
const AM_offsetDate = (date, z) => {
    if (z) {
        //manually specified zone
        return AM_dateWithManualTimezoneOffset(new Date(date), z);

    } else {
        const offsetMinutes = new Date(date).getTimezoneOffset();
        const offsetMilliseconds = offsetMinutes * 60000;
        return new Date(date + offsetMilliseconds);
    }
}

/***********************************************
 *
 *  Offset date in miliseconds based on manual time zone specified
 *
 *  Input: date = date in miliseconds
 *
 ***********************************************/
const AM_dateWithManualTimezoneOffset = (d, z) => {
    var d1;
    if (z) {
        d1 = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours() + AM_manualTimezoneOffset(d, z), d.getUTCMinutes(), d.getUTCSeconds()); // Create new date which incorporates the time zone offset
    } else {
        d1 = d;
    }
    return d1;
}

/***********************************************
 *
 *  Offset date in miliseconds based on manually specified time zone
 *
 *  Input: date = date in miliseconds, zone = zone id
 *
 ***********************************************/
const AM_manualTimezoneOffset = (d, z) => {
    let hours = 0;
    if (z) {
        TIME_ZONES.forEach((zone) => {
            if (z === zone.id) {
                var dst_start = new Date(d.getFullYear(), DAYLIGHT_SAVINGS.start.month, DAYLIGHT_SAVINGS.start.day, DAYLIGHT_SAVINGS.start.hour, DAYLIGHT_SAVINGS.start.minute); // Get start of daylight savings time
                var dst_end = new Date(d.getFullYear(), DAYLIGHT_SAVINGS.end.month, DAYLIGHT_SAVINGS.end.day, DAYLIGHT_SAVINGS.end.hour, DAYLIGHT_SAVINGS.end.minute); // Get the end of daylight savings time
                if (d >= dst_start && d <= dst_end) { // If date is during daylight savings time
                    hours = zone.dst_offset; // Use daylight savings time offset
                } else {
                    hours =  zone.std_offset; // Otherwise use standard time offset
                }
            }
        });
    }
    return hours;
}

/***********************************************
 *
 *  Formats a number based on the locale passed in (e.g. 14567 => 14,567)
 *
 *  Input:
 *  number = number
 *  locale = the locale the user is currently in (e.g. 'en-US')
 *  sigDigits = the number of digits to round the number to, if none is passed the number will be rounded to nearest whole number
 *
 *  @return {string}
 ***********************************************/
const AM_formatNumber = (number, locale, sigDigits) => {

return new Intl.NumberFormat(locale, { maximumFractionDigits: sigDigits }).format(number);
}

/***********************************************
 *
 *  Formats a currency value based on number passed in (e.g. 14567 => $14,567)
 *
 *  Input:
 *  number = number
 *  locale = the locale the user is currently in (e.g. 'en-US')
 *  currency = the currency code to use (e.g. 'USD)
 *  sigDigits = the number of digits to round the number to, if none is passed the number will be rounded to 2 decimal points
 *
 *  @return {string}
 ***********************************************/
const AM_formatCurrency = (number, locale, currency, sigDigits) => {
    return new Intl.NumberFormat(locale, {style: 'currency', currency: currency, maximumFractionDigits: sigDigits}).format(number);
}

/***********************************************
 *
 *  Formats a distance value based on number passed in (e.g. 14567 => 14,567 miles)
 *
 *  Input:
 *  number = number
 *  locale = the locale the user is currently in (e.g. 'en-US')
 *  distanceType = (1 = imperial/miles; 2 = metric/kilometers)
 *  sigDigits = the number of digits to round the number to, if none is passed the number will be rounded to 0 decimal points
 *
 *  @return {string}
 ***********************************************/
const AM_formatDistance = (number, locale, distanceType, sigDigits, unitDisplayLength, altUnitType) => {
    const unit = altUnitType ? altUnitType : distanceType === UNIT_TYPE_IMPERIAL ? 'mile' : 'kilometer';
    return new Intl.NumberFormat(locale, {style: 'unit', unit: unit, unitDisplay: unitDisplayLength ? unitDisplayLength : 'long', maximumFractionDigits: sigDigits}).format(number);
}
/***********************************************
 *
 *  Gets distance unit text based on locale and distance type (e.g. miles)
 *
 *  Input:
 *  number = number
 *  locale = the locale the user is currently in (e.g. 'en-US')
 *  distanceType = (1 = imperial/miles; 2 = metric/kilometers)
 *
 *  @return {string}
 ***********************************************/
const AM_getUnitForDistance = (number, locale, distanceType) => {
    const unit = distanceType == UNIT_TYPE_IMPERIAL ? 'mile' : 'kilometer';
    const numberParts = new Intl.NumberFormat(locale, {style: 'unit', unit: unit, unitDisplay: 'long'}).formatToParts(number);
    const unitPart = numberParts.find(part => part.type == 'unit');
    return unitPart.value;
}
/***********************************************
 *
 *  Formats a volume value based on number passed in (e.g. 14567 => 14,567 gallons)
 *
 *  Input:
 *  number = number
 *  locale = the locale the user is currently in (e.g. 'en-US')
 *  distanceType = (1 = imperial/gallons; 2 = metric/liters)
 *  sigDigits = the number of digits to round the number to, if none is passed the number will be rounded to 0 decimal points
 *
 *  @return {string}
 ***********************************************/
const AM_formatVolume = (number, locale, volumeType, sigDigits) => {
    const unit = volumeType == UNIT_TYPE_IMPERIAL ? 'gallon' : 'liter';
    return new Intl.NumberFormat(locale, {style: 'unit', unit: unit, unitDisplay: 'long'}).format(number);
}

/***********************************************
 *
 *  Formats a weight value based on number passed in (e.g. 14567 => 14,567 lbs)
 *
 *  Input:
 *  number = number
 *  locale = the locale the user is currently in (e.g. 'en-US')
 *  distanceType = (1 = imperial/pounds; 2 = metric/kilograms)
 *  sigDigits = the number of digits to round the number to, if none is passed the number will be rounded to 0 decimal points
 *
 *  @return {string}
 ***********************************************/
const AM_formatWeight = (number, locale, weightType, sigDigits) => {
    const unit = weightType === UNIT_TYPE_IMPERIAL ? 'pound' : 'kilogram';
    return new Intl.NumberFormat(locale, {style: 'unit', unit: unit, unitDisplay: 'long'}).format(number);
}
/***********************************************
 *
 *  Gets volume unit text based on locale and volume type (e.g. gallons)
 *
 *  Input:
 *  number = number
 *  locale = the locale the user is currently in (e.g. 'en-US')
 *  volumeType = (1 = imperial/gallons; 2 = metric/liters)
 *
 *  @return {string}
 ***********************************************/
const AM_getUnitForVolume = (number, locale, volumeType) => {
    const unit = volumeType == UNIT_TYPE_IMPERIAL ? 'gallon' : 'liter';
    const numberParts = new Intl.NumberFormat(locale, {style: 'unit', unit: unit, unitDisplay: 'long'}).formatToParts(number);
    const unitPart = numberParts.find(part => part.type == 'unit');
    return unitPart.value;
}



/***********************************************
 *
 *  Creates a locale from a language and a country string
 *  ie. en + US -> en-US
 *
 ***********************************************/
const AM_createLocaleFromLanguage = (lang, country) => {
    return `${lang}-${country}`;
    // return `${lang.slice(0, 2)}-${lang.slice(2)}`;
}

/***********************************************
 *
 *  Converts lbs to tons
 *  ie. 2000 lbs => 1 ton
 *
 ***********************************************/
const AM_convertLbstoTons = (number) => {
    return number/2000.0;
}


/***********************************************
 *
 *  Create an address string from an address object
 *
 *  Input: addr = object with street, city, state, zip
 *  Return: address string
 *
 *  @return {string}
 ***********************************************/
const AM_addressString = (addr) => {
    let address = "";
    if (addr.street) {
        if (addr.street.trim().length > 0) {
            address += addr.street + ", ";
        }
    }
    else if (addr.street1) {
        if (addr.street1.trim().length > 0) {
            address += addr.street1 + ", ";
        }
    }
    if (addr.city) {
        if (addr.city.trim().length > 0) {
            address += addr.city + ", ";
        }
    }
    if (addr.state)  address += addr.state + " ";
    if (addr.zip) {
        address += addr.zip;
    } else if (addr.postalCode) {
        address += addr.postalCode;
    }
    return address;
}

/***********************************************
 *
 *  Return the percent value of a number
 ***********************************************/
const AM_toPercentString = (number) => {
    var percent = number * 100;
    if (percent > 1.0) {
        return percent.toFixed(0);
    } else if (percent > 0.0) {
        return "< 1";
    } else {
        return "0";
    }
}

/***********************************************
 *
 *  Returns an emissions string for a special event metric
 *
 ***********************************************/
const AM_toEmissionsString = (number, locale) => {
    if (number === 0 || number >= 1) {
        return AM_formatNumber(number, locale, 0);
    } else if (number < 0.1) {
        return AM_formatNumber(number, locale, 2);
    } else {
        return AM_formatNumber(number, locale, 1);
    }
}


/***********************************************
 *
 *  Returns text for an event aggregates value
 *
 ***********************************************/
const AM_getAggValueText = (metric_id, value, locale, currency) => {
    switch (metric_id) {
        case LEADERBOARD_METRIC_GREENER_TRIPS:
        case LEADERBOARD_METRIC_NEW_MEMBERS:
        case LEADERBOARD_METRIC_PARTICIPANTS:
        case LEADERBOARD_METRIC_ACTIVE_MEMBERS:
        case LEADERBOARD_METRIC_TOTAL_MEMBERS:
        case LEADERBOARD_METRIC_CALORIES_BURNED:
        case LEADERBOARD_METRIC_TEAM_SPIRIT:
        case LEADERBOARD_METRIC_CARPOOL_TRIPS:
        case LEADERBOARD_METRIC_RIDESHARE_TRIPS:
        case LEADERBOARD_METRIC_VANPOOL_TRIPS:
        case LEADERBOARD_METRIC_BIKE_TRIPS:
        case LEADERBOARD_METRIC_WALK_TRIPS:
        case LEADERBOARD_METRIC_TRANSIT_TRIPS:
        case LEADERBOARD_METRIC_MULTIMODE_TRIPS:
        case LEADERBOARD_METRIC_TELECOMMUTES:
        case LEADERBOARD_METRIC_COMPRESSED_WEEKS:
        case LEADERBOARD_METRIC_MILES_NOT_DRIVEN:
        case LEADERBOARD_METRIC_REDUCED_CAR_TRIPS:
        case LEADERBOARD_METRIC_BROWN_BAGS:
        case LEADERBOARD_METRIC_SCOOTER_TRIPS:
        case LEADERBOARD_METRIC_GALLONS_GAS_SAVED:
        case BENEFIT_GAS:
        case BENEFIT_MILES:
        case BENEFIT_CALORIES:
        case BENEFIT_POINTS:
            return AM_formatNumber(value, locale, 0);
        case LEADERBOARD_METRIC_SCOOTER_TRIPS_PCT:
        case LEADERBOARD_METRIC_BROWN_BAGS_PCT:
        case LEADERBOARD_METRIC_COMPRESSED_WEEKS_PCT:
        case LEADERBOARD_METRIC_TELECOMMUTES_PCT:
        case LEADERBOARD_METRIC_MULTIMODE_TRIPS_PCT:
        case LEADERBOARD_METRIC_TRANSIT_TRIPS_PCT:
        case LEADERBOARD_METRIC_WALK_TRIPS_PCT:
        case LEADERBOARD_METRIC_BIKE_TRIPS_PCT:
        case LEADERBOARD_METRIC_VANPOOL_TRIPS_PCT:
        case LEADERBOARD_METRIC_CARPOOL_TRIPS_PCT:
        case LEADERBOARD_METRIC_RIDESHARE_TRIPS_PCT:
        case LEADERBOARD_METRIC_PART_RATE_REGISTERED:
        case LEADERBOARD_METRIC_PART_RATE_EMPLOYEES:
            return AM_toPercentString(value) + "%";
        case LEADERBOARD_METRIC_MONEY_SAVED:
        case BENEFIT_REWARDS:
        case BENEFIT_SAVINGS:
            return AM_formatCurrency(value, locale, currency, 0);
        case LEADERBOARD_METRIC_EMISSIONS_PREVENTED:
        case BENEFIT_EMISSIONS:
            return AM_toEmissionsString(value, locale);
    }
}

/***********************************************
 *
 *  Returns text for an event race value
 *
 ***********************************************/
const AM_createRaceResultText = (metric_id, value, label, locale) => {
    switch (metric_id) {
        case LEADERBOARD_METRIC_TEAM_SPIRIT:
            return label;
        case LEADERBOARD_METRIC_EMISSIONS_PREVENTED:
            return `${AM_toEmissionsString(value, locale)} ${label}`;
        case LEADERBOARD_METRIC_RIDESHARE_TRIPS_PCT:
        case LEADERBOARD_METRIC_CARPOOL_TRIPS_PCT:
        case LEADERBOARD_METRIC_VANPOOL_TRIPS_PCT:
        case LEADERBOARD_METRIC_TRANSIT_TRIPS_PCT:
        case LEADERBOARD_METRIC_TELECOMMUTES_PCT:
        case LEADERBOARD_METRIC_BIKE_TRIPS_PCT:
        case LEADERBOARD_METRIC_WALK_TRIPS_PCT:
        case LEADERBOARD_METRIC_COMPRESSED_WEEKS_PCT:
        case LEADERBOARD_METRIC_MULTIMODE_TRIPS_PCT:
        case LEADERBOARD_METRIC_PART_RATE_REGISTERED:
        case LEADERBOARD_METRIC_PART_RATE_EMPLOYEES:
        case LEADERBOARD_METRIC_BROWN_BAGS_PCT:
        case LEADERBOARD_METRIC_SCOOTER_TRIPS_PCT:
            return `${AM_toPercentString(value)}${label}`;
        case LEADERBOARD_METRIC_MONEY_SAVED:
            return `${label}${AM_formatNumber(value, locale, 0)}`;
        case LEADERBOARD_METRIC_GREENER_TRIPS:
        case LEADERBOARD_METRIC_NEW_MEMBERS:
        case LEADERBOARD_METRIC_ACTIVE_MEMBERS:
        case LEADERBOARD_METRIC_PARTICIPANTS:
        case LEADERBOARD_METRIC_TOTAL_MEMBERS:
        case LEADERBOARD_METRIC_CARPOOL_TRIPS:
        case LEADERBOARD_METRIC_VANPOOL_TRIPS:
        case LEADERBOARD_METRIC_RIDESHARE_TRIPS:
        case LEADERBOARD_METRIC_BIKE_TRIPS:
        case LEADERBOARD_METRIC_WALK_TRIPS:
        case LEADERBOARD_METRIC_TRANSIT_TRIPS:
        case LEADERBOARD_METRIC_MULTIMODE_TRIPS:
        case LEADERBOARD_METRIC_TELECOMMUTES:
        case LEADERBOARD_METRIC_COMPRESSED_WEEKS:
        case LEADERBOARD_METRIC_MILES_NOT_DRIVEN:
        case LEADERBOARD_METRIC_CALORIES_BURNED:
        case LEADERBOARD_METRIC_GALLONS_GAS_SAVED:
        case LEADERBOARD_METRIC_REDUCED_CAR_TRIPS:
        case LEADERBOARD_METRIC_BROWN_BAGS:
        case LEADERBOARD_METRIC_SCOOTER_TRIPS:
        default:
            return `${AM_formatNumber(value, locale, 0)} ${label}`;
    }
}

/***********************************************
 *
 *  Returns text for a time (12:00 am)
 *
 ***********************************************/
const AM_timeAMPM = (d) => {
    let ampm;
    let hh = d.getHours();
    let mm = d.getMinutes();

    if (hh < 12) {
        ampm = " am";
    } else {
        ampm = " pm";
    }
    if (hh == 0) {
        hh = 12;
    } else if (hh > 12) {
        hh = hh - 12;
    }
    if (mm < 10) {
        mm = "0" + mm;
    }
    return (hh + ":" + mm + ampm);
}

/***********************************************
 *
 *  Formats a phone number : 5555555555 -> (555) 555-5555
 *
 ***********************************************/
const AM_formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }

    return "";
};

/***********************************************
 *
 *  Creates an Address String from a passed in location object
 *
 ***********************************************/
const AM_createLocationAddressString = ({street, city, state, zip}) => {
    return `${street}, ${city}, ${state} ${zip}`;
}

/***********************************************
 *
 *  Creates an Address String and conditionally include name
 *
 ***********************************************/
const AM_createLocationAddressStringWithName = ({name, street, city, state, zip}) => {
    const includeName = name && name.length > 0 && !street.toLowerCase().includes(name.toLowerCase());
    return `${includeName ? `${name}, ` : ""}${street}, ${city}, ${state} ${zip}`;
}

/***********************************************
 *
 *  Creates an Address String from a suggested USPS address
 *
 ***********************************************/
const AM_createUSPSAddressString = ({address1, address2, city, state, zip}) => {
    return `${address1}, ${address2 && address2.length ? `${address2}, ` : ""}${city}, ${state} ${zip}`;
}

/***********************************************
 *
 *  Gets route duration text for the trip planner (ie. 2 hrs 16 mins)
 *
 ***********************************************/
const AM_getRouteDurationText = (route, isGoogle, t) => {
    let content = "";
    let seconds = 0;
    if (isGoogle) {
        const leg = route.legs[0];
        if (leg.duration.value && leg.duration.value > 0) seconds = leg.duration.value;
    } else {
        if (route.duration && route.duration > 0) seconds = route.duration;
    }

    if (seconds > 0) {
        let mins = Math.ceil(Math.max(seconds / 60.0, 1));
        let hrs = 0;
        let days = 0;
        if (mins >= 60) {
            hrs = Math.floor(mins / 60.0);
            mins = Math.round(mins - (hrs * 60));
        }
        if (hrs > 24) {
            days = Math.floor(hrs / 24.0);
            hrs = Math.round(hrs - (days * 24));
        }
        if (days > 0) {
            content += days.toFixed(0) + " " + (days !== 1 ? t('format_string_days') : t('format_string_day'));
        }
        if (hrs > 0) {
            if (content.length > 0) {
                content += " ";
            }
            content += hrs.toFixed(0) + " " + (hrs !== 1 ? t('format_string_hrs') : t('format_string_hr'));
        }
        if (mins > 0) {
            if (content.length > 0) {
                content += " ";
            }
            content += mins.toFixed(0) + " " + (mins !== 1 ? t('format_string_mins') : t('format_string_min')) ;
        }
    }
    return content;
}

/***********************************************
 *
 *  Gets route distance text when passed in meters
 *
 ***********************************************/
const AM_distanceTextMeters = (meters, fixedFormat, locale, unitType) => {
    return AM_distanceTextMiles(meters / METERS_PER_MILE, fixedFormat, locale, unitType);
}

/***********************************************
 *
 *  Gets route distance text when passed in miles
 *
 ***********************************************/
const AM_distanceTextMiles = (miles, fixedFormat, locale, unitType) => {
    let distance = "";
    if (fixedFormat) {
        distance = AM_formatDistance(miles, locale, unitType, 1, 'short');
    } else {
        if (miles >= 0) {
            let sig_digits = 0;
            if (miles >= 0.10) {
                if (miles < 10) {
                    sig_digits = 1;
                }
                distance = AM_formatDistance(miles, locale, unitType, sig_digits, 'short');
            } else {
                let feet = miles * 5280;
                if (feet < 1) {
                    sig_digits = 1;
                }
                distance = AM_formatDistance(feet, locale, unitType, sig_digits, 'short', 'foot');
            }
        }
    }
    return distance;
}

/***********************************************
 *
 *  Converts a number of minutes
 *  into X:XX am/pm time format
 *  Input:
 *  m = the number of minutes
 *  t = t function for translation
 *
 *    Returns local time @ X:XX am/pm format
 *  @return {string}
 ***********************************************/
function AM_minutesToTimeAMPM(m, t) {
    let hh = Math.floor(m / 60);
    let mm = m % 60;
    let ampm;
    let timeString = "";
    if (hh === 0 && mm === 0) {
        timeString = t("commute_times_label_midnight", {ns: "common"});
    } else if (hh === 12 && mm === 0) {
        timeString = t("commute_times_label_noon", {ns: "common"});
    } else {
        if (hh < 12) {
            ampm = "AM";
        } else {
            ampm = "PM";
        }
        if (hh === 0) {
            hh = 12;
        } else if (hh > 12) {
            hh = hh - 12;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        timeString = hh + ":" + mm + " " + ampm;
    }
    return timeString;
}

/***********************************************
 *
 *  Removes any formatting in phone number
 *
 ***********************************************/
function AM_removePhoneFormatting(phone) {
    var nums = phone.match(/[0-9]+/g);
    var phoneNumbers = "";
    if (nums && nums.length > 0) {
        phoneNumbers = nums.join("");
    }
    return phoneNumbers;
}

/***********************************************
 *
 *  Formats a phone number (ie. (123) 123-1234)
 *
 ***********************************************/
function AM_formatPhoneString(phone) {
    phone = AM_removePhoneFormatting(phone); // Remove pre-existing formatting
    if (phone.length == 10) {
        return "(" + phone.slice(0, 3) + ") " + phone.slice(3, 6) + "-" + phone.slice(6); // Add formatting
    } else {
        return phone;
    }
}



export {AM_createLocationAddressStringWithName, AM_datetoMonAbbreviatedString, AM_createLocaleFromLanguage,
    AM_formatNumber, AM_formatCurrency, AM_formatDistance, AM_formatVolume, AM_getUnitForDistance, AM_getUnitForVolume,
    AM_convertLbstoTons, AM_datetoMonDayYearAbbreviatedString, AM_offsetDate, AM_datetoMMDD, AM_datetoMMDDYY, AM_addressString,
    AM_toPercentString, AM_createRaceResultText, AM_getAggValueText,
    AM_timeAMPM, AM_formatPhoneNumber, AM_createLocationAddressString, AM_datetoMonDayAbbreviatedString,
    AM_datetoMonDayString, AM_formatWeight, AM_getRouteDurationText, AM_distanceTextMeters, AM_distanceTextMiles,
    AM_createUSPSAddressString, AM_minutesToTimeAMPM, AM_formatPhoneString, AM_removePhoneFormatting};







