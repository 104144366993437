/***********************************************
 *
 *  Helper functions to be used throughout the app
 *
 ***********************************************/
import * as constants from "./constants";
import {
    BENEFIT_CALORIES, BENEFIT_EMISSIONS, BENEFIT_GAS, BENEFIT_MILES, BENEFIT_POINTS, BENEFIT_REWARDS, BENEFIT_SAVINGS,
    BIKE_MODE,
    BROWN_BAG_MODE,
    COMP_MODE,
    DEFAULT_TRIP_HOUR,
    DEFAULT_TRIP_MIN,
    DEFAULT_TRIP_SECONDS,
    DRIVE_ALONE_MODE, EPA_VEHICLE_URL_FULL_SITE, EPA_VEHICLE_URL_MOBILE_SITE,
    LEADERBOARD_METRIC_ACTIVE_MEMBERS,
    LEADERBOARD_METRIC_BIKE_TRIPS,
    LEADERBOARD_METRIC_BIKE_TRIPS_PCT,
    LEADERBOARD_METRIC_BROWN_BAGS,
    LEADERBOARD_METRIC_BROWN_BAGS_PCT,
    LEADERBOARD_METRIC_CALORIES_BURNED,
    LEADERBOARD_METRIC_CARPOOL_TRIPS,
    LEADERBOARD_METRIC_CARPOOL_TRIPS_PCT,
    LEADERBOARD_METRIC_COMPRESSED_WEEKS,
    LEADERBOARD_METRIC_COMPRESSED_WEEKS_PCT,
    LEADERBOARD_METRIC_EMISSIONS_PREVENTED,
    LEADERBOARD_METRIC_GALLONS_GAS_SAVED,
    LEADERBOARD_METRIC_GREENER_TRIPS,
    LEADERBOARD_METRIC_MILES_NOT_DRIVEN,
    LEADERBOARD_METRIC_MONEY_SAVED,
    LEADERBOARD_METRIC_MULTIMODE_TRIPS,
    LEADERBOARD_METRIC_MULTIMODE_TRIPS_PCT,
    LEADERBOARD_METRIC_NEW_MEMBERS,
    LEADERBOARD_METRIC_PART_RATE_EMPLOYEES,
    LEADERBOARD_METRIC_PART_RATE_REGISTERED,
    LEADERBOARD_METRIC_PARTICIPANTS,
    LEADERBOARD_METRIC_REDUCED_CAR_TRIPS,
    LEADERBOARD_METRIC_RIDESHARE_TRIPS,
    LEADERBOARD_METRIC_RIDESHARE_TRIPS_PCT,
    LEADERBOARD_METRIC_SCOOTER_TRIPS,
    LEADERBOARD_METRIC_SCOOTER_TRIPS_PCT,
    LEADERBOARD_METRIC_TEAM_SPIRIT,
    LEADERBOARD_METRIC_TELECOMMUTES,
    LEADERBOARD_METRIC_TELECOMMUTES_PCT,
    LEADERBOARD_METRIC_TOTAL_MEMBERS,
    LEADERBOARD_METRIC_TRANSIT_TRIPS,
    LEADERBOARD_METRIC_TRANSIT_TRIPS_PCT,
    LEADERBOARD_METRIC_VANPOOL_TRIPS,
    LEADERBOARD_METRIC_VANPOOL_TRIPS_PCT,
    LEADERBOARD_METRIC_WALK_TRIPS,
    LEADERBOARD_METRIC_WALK_TRIPS_PCT,
    MILLISECONDS_PER_DAY, MILLISECONDS_PER_MINUTE,
    MULTIMODE_MODE,
    PARTNER_STATUSES,
    RIDESHARE_DRIVER_MODE,
    RIDESHARE_MODE,
    RIDESHARE_RIDER_MODE,
    SCOOTER_MODE,
    TELECOMMUTE_MODE,
    TRANSIT_MODE,
    WALK_MODE, WEB_SERVICES_QUMMUTE_ERROR, WEB_SERVICES_STATUS_OK
} from "./constants";
import DOMPurify from 'dompurify';
import {AM_addressString} from "./formatting";
import carpoolRider from "../assets/images/icons/rider_icon.svg";
import carpoolDriver from "../assets/images/icons/rideshare_icon.svg";
import transitIcon from "../assets/images/icons/transit_icon.svg";
import bikeIcon from "../assets/images/icons/bike_icon.svg";
import walkIcon from "../assets/images/icons/walk_icon_white.svg";
import telecommuteIcon from "../assets/images/icons/telecommute_icon.svg";
import compIcon from "../assets/images/icons/comp_work_week_icon.svg";
import multiIcon from "../assets/images/icons/multimode_icon.svg";
import brownbagIcon from "../assets/images/icons/brown_bag_icon.svg";
import driveAloneIcon from "../assets/images/icons/drive_alone_icon.svg";
import scooterIcon from "../assets/images/icons/scooter_icon.svg";
import greenerTripsIcon from "../assets/images/icons/greener_trip_icon.svg";
import vanpoolIcon from "../assets/images/icons/vanpool_icon@2x.png";
import milesIcon from "../assets/images/icons/home_icon_miles.svg";
import gasIcon from "../assets/images/icons/home_icon_gas.svg";
import emissionsIcon from "../assets/images/icons/home_icon_emissions.svg";
import caloriesIcon from "../assets/images/icons/home_icon_calories.svg";
import savingsIcon from "../assets/images/icons/home_icon_savings.svg";
import reducedCarTripIcon from "../assets/images/icons/reduced_car_trip_icon.png";
import teamSpiritIcon from "../assets/images/icons/team_spirit_icon.png";
import rewardsRedeemedIcon from "../assets/images/icons/home_icon_rewards.svg";
import pointsIcon from "../assets/images/icons/home_icon_points@2x.png";


/***********************************************
 *
 *  Retrieves and returns the logos for the brand
 *
 ***********************************************/
const AM_getBrandLogos = (brand) => {
    const logos = require.context('../assets/images/branding', true);
    const logoDark = logos(`./${brand}/logoDark.png`);
    const logo = logos(`./${brand}/logo.png`);
    const logoLanding = logos(`./${brand}/logoLanding.png`);

    return {
        logo: logo,
        logoDark: logoDark,
        logoLanding: logoLanding
    }
}
/***********************************************
 *
 *  Extract search parameter from URL (e.g. ?topicID=6 )
 *
 *  Input: name = name of search term (e.g. "topicID")
 *
 *  Returns search parameter (e.g. "6")
 *  @return {string}
 ***********************************************/
const AM_getURLParameter = (name, defaultValue) => {
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    const regexS = "[\\?&]" + name + "=([^&#]*)";
    const regex = new RegExp(regexS);
    const results = regex.exec(window.location.search);
    if (results == null) {
        return defaultValue ? defaultValue : "";
    } else {
        return decodeURIComponent(results[1].replace(/\+/g, " "));
    }
}
/***********************************************
 *
 *  Randomizes an array
 *
 ***********************************************/
const AM_randomizeArray = (array) => {

    if (array.length > 1) {

        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
    }
    return array;
}
/***********************************************
 *
 *  Tests if a proposed password is valid (contains 1 letter, 1 number, and is between min and max length)
 *
 ***********************************************/
const AM_testPassword = (str) => {
    return (/[a-z]/.test(str.toLowerCase()) && /\d/.test(str) && str.length >= constants.PASSWORD_MIN && str.length <= constants.PASSWORD_MAX);
}
/***********************************************
 *
 *  Creates object to pass into 'dangerouslysetinnerhtml' react function.
 *
 ***********************************************/
//this uses DOMPurify to sanitize html and remove any nasty scripts. We've added 'onclick' to the list of approved attributes since we use this alot.
// May need to add more in the future if we come across other attributes we need to allow that are blocked by default
const AM_createHTMLObject = (content) => ({__html: DOMPurify.sanitize(content, {ADD_ATTR: ['onclick', 'target']})});

/***********************************************
 *
 *  This returns an object of system attributes to be used for debugging contact requests, etc.
 *
 ***********************************************/
const AM_getSystemAttributes = () => {
    let unknown = '-';

    // screen
    let screenSize = '';
    if (window.screen.width) {
        let screen = window.screen;
        let width = (screen.width) ? screen.width : '';
        let height = (screen.height) ? screen.height : '';
        screenSize += '' + width + " x " + height;
    }

    // browser
    let nVer = navigator.appVersion;
    let nAgt = navigator.userAgent;
    let browser = navigator.appName;
    let version = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    // Opera
    if ((verOffset = nAgt.indexOf('Opera')) != -1) {
        browser = 'Opera';
        version = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf('Version')) != -1) {
            version = nAgt.substring(verOffset + 8);
        }
    }
    // Opera Next
    if ((verOffset = nAgt.indexOf('OPR')) != -1) {
        browser = 'Opera';
        version = nAgt.substring(verOffset + 4);
    }
    // Legacy Edge
    else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
        browser = 'Microsoft Legacy Edge';
        version = nAgt.substring(verOffset + 5);
    }
    // Edge (Chromium)
    else if ((verOffset = nAgt.indexOf('Edg')) != -1) {
        browser = 'Microsoft Edge';
        version = nAgt.substring(verOffset + 4);
    }
    // MSIE
    else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
        browser = 'Microsoft Internet Explorer';
        version = nAgt.substring(verOffset + 5);
    }
    // Chrome
    else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
        browser = 'Chrome';
        version = nAgt.substring(verOffset + 7);
    }
    // Safari
    else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
        browser = 'Safari';
        version = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf('Version')) != -1) {
            version = nAgt.substring(verOffset + 8);
        }
    }
    // Firefox
    else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
        browser = 'Firefox';
        version = nAgt.substring(verOffset + 8);
    }
    // MSIE 11+
    else if (nAgt.indexOf('Trident/') != -1) {
        browser = 'Microsoft Internet Explorer';
        version = nAgt.substring(nAgt.indexOf('rv:') + 3);
    }
    // Other browsers
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
        browser = nAgt.substring(nameOffset, verOffset);
        version = nAgt.substring(verOffset + 1);
        if (browser.toLowerCase() == browser.toUpperCase()) {
            browser = navigator.appName;
        }
    }
    // trim the version string
    if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

    majorVersion = parseInt('' + version, 10);
    if (isNaN(majorVersion)) {
        version = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }

    // mobile version
    let mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

    // cookie
    let cookieEnabled = (navigator.cookieEnabled) ? true : false;

    if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
        document.cookie = 'testcookie';
        cookieEnabled = (document.cookie.indexOf('testcookie') != -1) ? true : false;
    }

    // system
    let os = unknown;
    let clientStrings = [
        {s:'Windows 10', r:/(Windows 10.0|Windows NT 10.0)/},
        {s:'Windows 8.1', r:/(Windows 8.1|Windows NT 6.3)/},
        {s:'Windows 8', r:/(Windows 8|Windows NT 6.2)/},
        {s:'Windows 7', r:/(Windows 7|Windows NT 6.1)/},
        {s:'Windows Vista', r:/Windows NT 6.0/},
        {s:'Windows Server 2003', r:/Windows NT 5.2/},
        {s:'Windows XP', r:/(Windows NT 5.1|Windows XP)/},
        {s:'Windows 2000', r:/(Windows NT 5.0|Windows 2000)/},
        {s:'Windows ME', r:/(Win 9x 4.90|Windows ME)/},
        {s:'Windows 98', r:/(Windows 98|Win98)/},
        {s:'Windows 95', r:/(Windows 95|Win95|Windows_95)/},
        {s:'Windows NT 4.0', r:/(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
        {s:'Windows CE', r:/Windows CE/},
        {s:'Windows 3.11', r:/Win16/},
        {s:'Android', r:/Android/},
        {s:'Open BSD', r:/OpenBSD/},
        {s:'Sun OS', r:/SunOS/},
        {s:'Chrome OS', r:/CrOS/},
        {s:'Linux', r:/(Linux|X11(?!.*CrOS))/},
        {s:'iOS', r:/(iPhone|iPad|iPod)/},
        {s:'Mac OS X', r:/Mac OS X/},
        {s:'Mac OS', r:/(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
        {s:'QNX', r:/QNX/},
        {s:'UNIX', r:/UNIX/},
        {s:'BeOS', r:/BeOS/},
        {s:'OS/2', r:/OS\/2/},
        {s:'Search Bot', r:/(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
    ];
    for (var id in clientStrings) {
        var cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
            os = cs.s;
            break;
        }
    }

    let osVersion = unknown;

    if (/Windows/.test(os)) {
        osVersion = /Windows (.*)/.exec(os)[1];
        os = 'Windows';
    }

    switch (os) {
        case 'Mac OS':
        case 'Mac OS X':
        case 'Android':
            osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(nAgt)[1];
            break;

        case 'iOS':
            osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
            osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
            break;
    }

    return {
        screen: screenSize,
        browser: browser,
        browserVersion: version,
        browserMajorVersion: majorVersion,
        mobile: mobile,
        os: os,
        osVersion: osVersion,
        cookies: cookieEnabled,
        };
}

/***********************************************
 *
 *  Check if an object is empty
 *
 ***********************************************/
const AM_isObjectEmpty = (obj) => {
    if (Object.keys(obj).length > 0) {
        return false;
    }
    return true;
}

/***********************************************
 *
 *  Convert a hex color code to RBGA
 *
 ***********************************************/
const AM_convertHex = (hex,opacity)=> {
    hex = hex.replace('#','');
    let r, g, b, result;
    r = parseInt(hex.substring(0,2), 16);
    g = parseInt(hex.substring(2,4), 16);
    b = parseInt(hex.substring(4,6), 16);
    result = 'rgba('+r+','+g+','+b+','+opacity/100+')';
    return result;
}
/***********************************************
 *
 *  Sorts a photo array based on their given priorites. If photos have the same priority, they are randomzied
 *
 ***********************************************/
const AM_createPhotoArray = (photos) => {
    let i, j, m;
    // Priority provided
    if (photos[0].priority >= 0) {

        // Sort based on priority
        photos.sort(AM_sortPriority);

        // Randomize photos with same priority
        let currentPriority = photos[0].priority;
        let samePriorityPhotos = [];
        let randomizedPhotos = [];
        for (i = 0; i < photos.length; i++) {
            if (currentPriority == photos[i].priority) {
                samePriorityPhotos.push(photos[i]);
            }
            else {
                currentPriority = photos[i].priority;
                if (samePriorityPhotos.length > 0) {
                    samePriorityPhotos = AM_randomizeArray(samePriorityPhotos);
                    for (j = 0; j < samePriorityPhotos.length; j++) {
                        randomizedPhotos.push(samePriorityPhotos[j]);
                    }
                    samePriorityPhotos = [];
                    samePriorityPhotos.push(photos[i]);
                }
                else {
                    randomizedPhotos.push(photos[i]);
                }
            }
        }
        if (samePriorityPhotos.length > 0) {
            samePriorityPhotos = AM_randomizeArray(samePriorityPhotos);
            for (j = 0; j < samePriorityPhotos.length; j++) {
                randomizedPhotos.push(samePriorityPhotos[j]);
            }
        }
        photos = randomizedPhotos;
    }

    // No priority, so just randomize
    else {
        photos = AM_randomizeArray(photos);
    }

    return photos;

    // let finalPhotos= [];
    // for (m=0; m < photos.length; m++) {
    //     finalPhotos.push(photos[m].src);
    // }
    //
    // return finalPhotos;

}

/***********************************************
 *
 *  Sorting function for photo priorities
 *
 ***********************************************/
const AM_sortPriority = (a, b) => {
    if (a.priority < b.priority) {
        return -1;
    }
    if (a.priority > b.priority) {
        return 1;
    }
    return 0;
}

/***********************************************
 *
 *  Make sure URL has "http://", "https://", or "ftp://" in front of it
 *
 *  Input: url
 *
 *  Returns: url (with "http://" pre-pended if needed)
 *
 ***********************************************/
const AM_ensureHTTP = (url) => {
    const http = "http://";
    const https = "https://";
    const ftp = "ftp://";
    if (url.toLowerCase().search(http) == -1 &&  // No http
        url.toLowerCase().search(https) == -1 &&  // No https
        url.toLowerCase().search(ftp) == -1) {  // No ftp
        url = http.concat(url); // Pre-pend the http prefix
    }
    return url;
}

/***********************************************
 *
 *  Input: url
 *  target (ie. "_blank")
 *
 ***********************************************/
const AM_openLink = (url, target) => {
    window.open(url, target);
}
/***********************************************
 *
 *  Determine if iOS (iPhone, iPad, iPod)
 *
 *  @return {boolean}
 ***********************************************/
const AM_iOS = () => {
    return (navigator.userAgent.match(/(iPad|iPhone|iPod)/g));
}

/***********************************************
 *
 *  Determine if Android device
 *
 *  @return {boolean}
 ***********************************************/
const AM_Android = () => {
    if (navigator.userAgent.toLowerCase().match(/(android)/g)) {
        return true;
    } else {
        return false;
    }
}
/***********************************************
 *
 *  Checks if user is on a mobile device
 *
 *  @return {boolean}
 ***********************************************/
const AM_isMobile = () => {
    return AM_iOS() || AM_Android();
}
/***********************************************
 *
 *  Adds a certain amount of minutes to a date
 *
 *  @return {date}
 ***********************************************/
const AM_addMinutesToDate = (date, minutes) => {
    return new Date(date.getTime() + minutes*60000);
}

/***********************************************
 *
 *  Find the previous Sunday
 *
 *  Input:
 *  d = date in JavaScript system format
 *
 *     Returns date of previous Sunday
 *          or passed date it if is already Sunday
 *
 ***********************************************/
const AM_previousSunday = (date) => {
    date.setHours(DEFAULT_TRIP_HOUR);
    date.setMinutes(DEFAULT_TRIP_MIN);
    date.setSeconds(DEFAULT_TRIP_SECONDS);
    date.setMilliseconds(0);
    if (date.getUTCDay() == 0) {
        return date;
    } // Today is Sunday
    else {
        return new Date(date.getTime() - (MILLISECONDS_PER_DAY * date.getUTCDay()));
    } // Get previous Sunday
}

/***********************************************
 *
 *  Find the next Sunday
 *
 *  Input:
 *  d = date in JavaScript system format
 *
 *     Returns date of next Sunday
 *
 ***********************************************/
const AM_nextSunday = (date) => {
    date.setHours(DEFAULT_TRIP_HOUR);
    date.setMinutes(DEFAULT_TRIP_MIN);
    date.setSeconds(DEFAULT_TRIP_SECONDS);

    return new Date(date.getTime() + (MILLISECONDS_PER_DAY * (7 - date.getUTCDay())));

}

/***********************************************
 *
 *  Find the next Saturday
 *
 *  Input:
 *  d = date in JavaScript system format
 *
 *     Returns date of next Sunday
 *
 ***********************************************/
const AM_nextSaturday = (date) => {
    date.setHours(DEFAULT_TRIP_HOUR);
    date.setMinutes(DEFAULT_TRIP_MIN);
    date.setSeconds(DEFAULT_TRIP_SECONDS);

    return new Date(date.getTime() + (MILLISECONDS_PER_DAY * (6 - date.getUTCDay())));

}
/***********************************************
 *
 *  Checks if URL has a valid pattern
 *
 *  Input: url string
 *
 *  Returns: true or false
 *  @return {boolean}
 ***********************************************/
const AM_validURL = (url) => {
    if (url && url.length > 0) {
        url = AM_ensureHTTP(url);
        //var regex = /(https?|ftp):\/\/(((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?/;
        // Edited original regex string to not allow "@" symbol.  While this is technically valid in a url, we are not allowing it since people are entering email by mistake
        const regex = /(https?|ftp):\/\/(((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|)+(\/(([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|)*)*)?)?(\?((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|)|\/|\?)*)?/;
        if (regex.test(url)) {
            // Don't allow spaces or @ symbol
            if (url.indexOf(' ') < 0 && url.indexOf('@') < 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}

/***********************************************
 *
 *  Prepare location so we can save it
 *
 *  Input: loc = location object
 *         loc.address_components = address components from Google
 *         callback
 *         callbackArg = callback argument
 *         tryAgain = boolean to try recursively
 *         maps = google maps instance
 *
 *  Return: loc.street
 *          loc.city
 *          loc.state
 *          loc.zip
 *
 ***********************************************/
const AM_prepareLocationForSaving = (loc, callback, callbackArg, tryAgain, maps, secondCallbackArg) => {
    if (loc && loc.address_components && loc.address_components.length > 0) {
        let street_number = null;
        let street_name   = null;
        let city          = null;
        let neighborhood  = null;
        let sublocality   = null;
        let state         = null;
        let zip           = null;
        let adminLevel3   = null;
        for (let i=0; i<loc.address_components.length; i++) {// Loop through components
            for (let j=0; j<loc.address_components[i].types.length; j++) { // Loop through component types
                if (loc.address_components[i].types[j] === "street_number") { // Street number
                    street_number = loc.address_components[i].long_name;
                }
                else if (loc.address_components[i].types[j] === "route") { // Street
                    street_name = loc.address_components[i].long_name;
                }
                else if (loc.address_components[i].types[j] === "locality") { // City
                    city = loc.address_components[i].long_name;
                }
                else if (loc.address_components[i].types[j] === "neighborhood") { // Neighborhood
                    neighborhood = loc.address_components[i].long_name;
                }
                else if (loc.address_components[i].types[j] === "sublocality" || loc.address_components[i].types[j] === "sublocality_level_1") { // City
                    sublocality = loc.address_components[i].long_name;
                }
                else if (loc.address_components[i].types[j]==='administrative_area_level_3') { // Admin level 3
                    adminLevel3 = loc.address_components[i].long_name;
                }
                else if (loc.address_components[i].types[j] === "administrative_area_level_1") { // State
                    state = loc.address_components[i].short_name;
                }
                else if (loc.address_components[i].types[j] === "postal_code") { // Zip
                    zip = loc.address_components[i].short_name;
                }
            }
        }
        if (!city && sublocality) city = sublocality;   // If no city, then use sublocality
        if (!city && adminLevel3) city = adminLevel3;   // If no city, then use adminLevel3
        if (!city && neighborhood) city = neighborhood; // If no city, then use neighborhood

        // Missing an essential address component
        if (tryAgain && (!city || !state || !street_name || !zip && loc.latlng)) {
            const map_geocoder = new maps.Geocoder();
            map_geocoder.geocode({'location': loc.latlng}, function (results, status) { // Reverse geocode to get missing components
                if (status == maps.GeocoderStatus.OK) { // Valid location returned
                    if (results && results.length > 0) {// Results returned

                        //loop through results
                        for (let k=0; k < results.length; k++) {
                            const additional_components = results[k].address_components //get components for each result

                            for (let i = 0; i < additional_components.length; i++) {// Loop through the components
                                const component = additional_components[i]; //save the current component
                                let componentNew = true;
                                for (let j = 0; j < loc.address_components.length; j++) { // Loop through existing components I already have
                                    if (AM_addressComponentsHaveSameTypes(component, loc.address_components[j])) {
                                        componentNew = false; //already have a component with this type, so it's not new
                                        break;
                                    }
                                }
                                if (componentNew) {
                                    // component is new so add it to my components
                                    loc.address_components.push(component);
                                }

                            }

                        }

                        AM_prepareLocationForSaving(loc, callback, callbackArg, false, null, secondCallbackArg);
                    }
                }
                else {
                    AM_prepareLocationForSaving(loc, callback, callbackArg, false, null, secondCallbackArg);
                }
            });
        }

        // May have all components
        else {
            // Have everything
            // if (state && state.length > 0 && city && city.length > 0 && zip && zip.length > 0 && street_name && street_name.length > 0) {
            if (state && state.length > 0 && city && city.length > 0 && zip && zip.length > 0) {
                loc.street  = ((street_number || street_name) ? ((street_number ? street_number : "") + (street_name ? (" " + street_name) : "")) : null);
                loc.city    = city;
                loc.state   = state;
                loc.zip     = zip;
                loc.address = AM_addressString(loc);
            }

            // Something is missing, null out location
            else {
                loc.street = null;
                loc.city = null;
                loc.state = null;
                loc.zip = null;
                loc.address = null;
                loc.latlng = null;

            }
            if (callback) callback(callbackArg, secondCallbackArg);
        }
    }
    else {
        if (loc.address == null || loc.address.length === 0) {
            loc.address = AM_addressString(loc);
        }
        if (callback) callback(callbackArg, secondCallbackArg);
    }
}


/***********************************************
 *
 *  Indicates if two address components share the same types
 *
 *  Input:  c1, c2 = address components
 *
 *  @return {Boolean}
 ***********************************************/
const AM_addressComponentsHaveSameTypes = (c1, c2) => {
    let same_types = false;
    if (c1.types && c2.types && c1.types.length === c2.types.length) {
        same_types = true;
        for (let i=0; i<c1.types.length; i++) {
            if (c1.types[i] !== c2.types[i]) {
                same_types = false;
                break;
            }
        }
    }
    return same_types;
}
/***********************************************
 *
 *  Returns the icon for a trip mode
 *
 ***********************************************/
const AM_tripIcon = (mode) => {
    switch (mode) {
        case (RIDESHARE_DRIVER_MODE):
        case (RIDESHARE_RIDER_MODE):
        case(RIDESHARE_MODE):
            return carpoolDriver;
        case (TRANSIT_MODE):
            return transitIcon;
        case (WALK_MODE):
            return walkIcon;
        case (BIKE_MODE):
            return bikeIcon;
        case (TELECOMMUTE_MODE):
            return telecommuteIcon;
        case (BROWN_BAG_MODE):
            return brownbagIcon;
        case (DRIVE_ALONE_MODE):
            return driveAloneIcon;
        case (SCOOTER_MODE):
            return scooterIcon;
        case (COMP_MODE):
            return compIcon;
        case (MULTIMODE_MODE):
            return multiIcon;
    }
}

    /***********************************************
     *
     *  Returns the text for the right theme color for the mode (refer to $theme-colors in _variables.scss)
     *
     ***********************************************/
    const AM_tripThemeText = (mode) => {
        switch (mode) {
            case (RIDESHARE_DRIVER_MODE):
            case (RIDESHARE_RIDER_MODE):
            case(RIDESHARE_MODE):
                return "rideshare";
            case (TRANSIT_MODE):
                return "transit";
            case (WALK_MODE):
                return "walk";
            case (BIKE_MODE):
                return "bike";
            case (TELECOMMUTE_MODE):
                return "telecommute";
            case (BROWN_BAG_MODE):
                return "brown-bag";
            case (DRIVE_ALONE_MODE):
                return "drive-alone";
            case (SCOOTER_MODE):
                return "scooter";
            case (COMP_MODE):
                return "cww";
            case (MULTIMODE_MODE):
                return "multimode";
        }
}

/***********************************************
 *
 *  Returns the translation key for a mode
 *
 ***********************************************/
const AM_tripModeTextKey = (mode) => {
    switch (mode) {
        case (RIDESHARE_DRIVER_MODE):
        case (RIDESHARE_RIDER_MODE):
        case(RIDESHARE_MODE):
            return "global_textview_label_mode_carpool_or_vanpool";
        case (TRANSIT_MODE):
            return "global_textview_label_mode_transit";
        case (WALK_MODE):
            return "global_textview_label_mode_walk";
        case (BIKE_MODE):
            return "global_textview_label_mode_bike";
        case (TELECOMMUTE_MODE):
            return "global_textview_label_mode_telecommute";
        case (BROWN_BAG_MODE):
            return "global_textview_label_mode_brown_bag";
        case (DRIVE_ALONE_MODE):
            return "global_textview_label_mode_drove_alone";
        case (SCOOTER_MODE):
            return "global_textview_label_mode_scooter";
        case (COMP_MODE):
            return "global_textview_label_mode_compressed_work_week";
        case (MULTIMODE_MODE):
            return "global_textview_label_mode_mulitmode";
    }
}

/***********************************************
 *
 *  Returns the key for abbreviated version of mode (for use in trip calendar)
 *
 ***********************************************/
const AM_tripModeTextAbbreviatedKey = (mode) => {
    switch (mode) {
        case (RIDESHARE_DRIVER_MODE):
        case (RIDESHARE_RIDER_MODE):
        case(RIDESHARE_MODE):
            return "weekday_view_textview_label_rideshare";
        case (TRANSIT_MODE):
            return "weekday_view_textview_label_transit";
        case (WALK_MODE):
            return "weekday_view_textview_label_walk";
        case (BIKE_MODE):
            return "weekday_view_textview_label_bike";
        case (TELECOMMUTE_MODE):
            return "weekday_view_textview_label_telecommute";
        case (BROWN_BAG_MODE):
            return "weekday_view_textview_label_brown_bag";
        case (DRIVE_ALONE_MODE):
            return "weekday_view_textview_label_drive_alone";
        case (SCOOTER_MODE):
            return "weekday_view_textview_label_scooter";
        case (COMP_MODE):
            return "weekday_view_textview_label_comp_week";
        case (MULTIMODE_MODE):
            return "weekday_view_textview_label_multimode";
    }
}

/***********************************************
 *
 *  Returns true/false to check if 2 provided dates are on the same day
 *
 ***********************************************/
const AM_datesOnSameDay = (d1, d2) => {
    //make a copy of the dates here so we're not modifying the original dates passed in
    const date1 = new Date(d1.getTime());
    const date2 = new Date(d2.getTime());
    date1.setHours(0,0,0,0);
    date2.setHours(0,0,0,0);
    return date1.getTime() === date2.getTime();
}

/***********************************************
 *
 *  Returns true/false to check if a given date is within the window in which it can be recorded
 *  date = the date to check
 *  recordTripDaysBack = the number of days back a user is allowed to record trips for (brand setting)
 *  recordTripDaysForward = the number of days forward a user is allowed to record trips for (brand setting)
 *
 ***********************************************/
const AM_dateWithinRecordableRange = (date, recordTripDaysBack, recordTripDaysForward) => {
    const minTime = new Date().getTime() - (MILLISECONDS_PER_DAY * recordTripDaysBack);
    const maxTime = new Date().getTime() + (MILLISECONDS_PER_DAY * recordTripDaysForward);
    return date.getTime() > minTime && date.getTime() < maxTime;

}

/***********************************************
 *
 *  Gets icon for event metric
 *
 ***********************************************/
const AM_getIconEventMetric = (metric_id) => {
    switch (metric_id) {
        case LEADERBOARD_METRIC_TEAM_SPIRIT:
            return teamSpiritIcon;
        case LEADERBOARD_METRIC_GREENER_TRIPS:
            return greenerTripsIcon;
        case LEADERBOARD_METRIC_NEW_MEMBERS:
        case LEADERBOARD_METRIC_PARTICIPANTS:
        case LEADERBOARD_METRIC_ACTIVE_MEMBERS:
        case LEADERBOARD_METRIC_TOTAL_MEMBERS:
        case LEADERBOARD_METRIC_PART_RATE_REGISTERED:
        case LEADERBOARD_METRIC_PART_RATE_EMPLOYEES:
            return carpoolRider;
        case LEADERBOARD_METRIC_CARPOOL_TRIPS:
        case LEADERBOARD_METRIC_CARPOOL_TRIPS_PCT:
        case LEADERBOARD_METRIC_RIDESHARE_TRIPS:
        case LEADERBOARD_METRIC_RIDESHARE_TRIPS_PCT:
            return carpoolDriver;
        case LEADERBOARD_METRIC_VANPOOL_TRIPS:
        case LEADERBOARD_METRIC_VANPOOL_TRIPS_PCT:
            return vanpoolIcon;
        case LEADERBOARD_METRIC_BIKE_TRIPS:
        case LEADERBOARD_METRIC_BIKE_TRIPS_PCT:
            return bikeIcon;
        case LEADERBOARD_METRIC_WALK_TRIPS:
        case LEADERBOARD_METRIC_WALK_TRIPS_PCT:
            return walkIcon;
        case LEADERBOARD_METRIC_TRANSIT_TRIPS:
        case LEADERBOARD_METRIC_TRANSIT_TRIPS_PCT:
            return transitIcon;
        case LEADERBOARD_METRIC_MULTIMODE_TRIPS:
        case LEADERBOARD_METRIC_MULTIMODE_TRIPS_PCT:
            return multiIcon;
        case LEADERBOARD_METRIC_TELECOMMUTES:
        case LEADERBOARD_METRIC_TELECOMMUTES_PCT:
            return telecommuteIcon;
        case LEADERBOARD_METRIC_COMPRESSED_WEEKS:
        case LEADERBOARD_METRIC_COMPRESSED_WEEKS_PCT:
            return compIcon;
        case LEADERBOARD_METRIC_MILES_NOT_DRIVEN:
        case BENEFIT_MILES:
            return milesIcon;
        case LEADERBOARD_METRIC_EMISSIONS_PREVENTED:
        case BENEFIT_EMISSIONS:
            return emissionsIcon;
        case LEADERBOARD_METRIC_CALORIES_BURNED:
        case BENEFIT_CALORIES:
            return caloriesIcon;
        case LEADERBOARD_METRIC_GALLONS_GAS_SAVED:
        case BENEFIT_GAS:
            return gasIcon;
        case LEADERBOARD_METRIC_MONEY_SAVED:
        case BENEFIT_SAVINGS:
            return savingsIcon;
        case LEADERBOARD_METRIC_REDUCED_CAR_TRIPS:
            return reducedCarTripIcon;
        case LEADERBOARD_METRIC_BROWN_BAGS:
        case LEADERBOARD_METRIC_BROWN_BAGS_PCT:
            return brownbagIcon;
        case LEADERBOARD_METRIC_SCOOTER_TRIPS:
        case LEADERBOARD_METRIC_SCOOTER_TRIPS_PCT:
            return scooterIcon;
        case BENEFIT_REWARDS:
            return rewardsRedeemedIcon;
        case BENEFIT_POINTS:
            return pointsIcon;
    }
}

/***********************************************
 *
 *  Returns theme text for a special event metric
 *
 ***********************************************/
const AM_getThemeTextEventMetric = (metric_id) => {
    switch (metric_id) {
        case LEADERBOARD_METRIC_TEAM_SPIRIT:
            return "team-spirit";
        case LEADERBOARD_METRIC_GREENER_TRIPS:
            return "greener-trips";
        case LEADERBOARD_METRIC_NEW_MEMBERS:
        case LEADERBOARD_METRIC_PARTICIPANTS:
        case LEADERBOARD_METRIC_ACTIVE_MEMBERS:
        case LEADERBOARD_METRIC_TOTAL_MEMBERS:
        case LEADERBOARD_METRIC_PART_RATE_REGISTERED:
        case LEADERBOARD_METRIC_PART_RATE_EMPLOYEES:
            return "member";
        case LEADERBOARD_METRIC_CARPOOL_TRIPS:
        case LEADERBOARD_METRIC_CARPOOL_TRIPS_PCT:
        case LEADERBOARD_METRIC_RIDESHARE_TRIPS:
        case LEADERBOARD_METRIC_RIDESHARE_TRIPS_PCT:
            return "rideshare";
        case LEADERBOARD_METRIC_VANPOOL_TRIPS:
        case LEADERBOARD_METRIC_VANPOOL_TRIPS_PCT:
            return "vanpool";
        case LEADERBOARD_METRIC_BIKE_TRIPS:
        case LEADERBOARD_METRIC_BIKE_TRIPS_PCT:
            return "bike";
        case LEADERBOARD_METRIC_WALK_TRIPS:
        case LEADERBOARD_METRIC_WALK_TRIPS_PCT:
            return "walk";
        case LEADERBOARD_METRIC_TRANSIT_TRIPS:
        case LEADERBOARD_METRIC_TRANSIT_TRIPS_PCT:
            return "transit";
        case LEADERBOARD_METRIC_MULTIMODE_TRIPS:
        case LEADERBOARD_METRIC_MULTIMODE_TRIPS_PCT:
            return "multimode";
        case LEADERBOARD_METRIC_TELECOMMUTES:
        case LEADERBOARD_METRIC_TELECOMMUTES_PCT:
            return "telecommute";
        case LEADERBOARD_METRIC_COMPRESSED_WEEKS:
        case LEADERBOARD_METRIC_COMPRESSED_WEEKS_PCT:
            return "cww";
        case LEADERBOARD_METRIC_MILES_NOT_DRIVEN:
            return "miles";
        case LEADERBOARD_METRIC_EMISSIONS_PREVENTED:
            return "emissions";
        case LEADERBOARD_METRIC_CALORIES_BURNED:
            return "calories";
        case LEADERBOARD_METRIC_GALLONS_GAS_SAVED:
            return "gas";
        case LEADERBOARD_METRIC_MONEY_SAVED:
            return "money";
        case LEADERBOARD_METRIC_REDUCED_CAR_TRIPS:
            return "reduced-trips";
        case LEADERBOARD_METRIC_BROWN_BAGS:
        case LEADERBOARD_METRIC_BROWN_BAGS_PCT:
            return "brown-bag";
        case LEADERBOARD_METRIC_SCOOTER_TRIPS:
        case LEADERBOARD_METRIC_SCOOTER_TRIPS_PCT:
            return "scooter";
    }
}

/***********************************************
 *
 *  Converts a string to title case
 *
 *  Input: str = string
 *
 *  Returns string in title case format
 ***********************************************/
function AM_toTitleCase(str) {
    return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

const AM_getPartnerStatusText = (id) => {
    let partnerStatus = PARTNER_STATUSES.find(status => status.id == id);
    if (partnerStatus) return partnerStatus.label;
    return "-"
}

/***********************************************
 *
 *  Takes a location returned from google places, parses the address components, and returns a location object broken out into parts (street, city, state)
 *
 *  Input: location = location returned from places auto-complete
 ***********************************************/
const AM_parseLocationAddressComponents = (location, name) => {
    let street_number = "";
    let street_name_long = "";
    let street_long = "";
    let city = "";
    let state = "";
    let zip = "";


    location.address_components.forEach(component => {
        if (component.types.includes("street_number")) {
            street_number = component.long_name;
        } else if (component.types.includes("route")) {
            street_name_long = component.long_name;
        } else if ((component.types.includes("locality"))) {
            city = component.long_name;
        } else if ((component.types.includes("administrative_area_level_1"))) {
            state = component.short_name;
        } else if ((component.types.includes("postal_code"))) {
            zip = component.short_name;
        }
    });

    if (street_number && street_number.length > 0) {
        street_long = street_number;
    }

    if (street_name_long && street_name_long.length > 0) {
        if (street_long && street_long.length > 0) {
            street_long += " ";
        }
        street_long += street_name_long;
    }

    return {
        name: name,
        street: street_long,
        city: city,
        state: state,
        zip: zip,
        lat: location.lat,
        lng: location.lng

    }
}
/***********************************************
 *
 *  Takes an address string and extracts the components from it, then returns an object
 *
 ***********************************************/
const AM_extractAddressComponentsFromString = (addressString) => {
    let components = addressString.split(",");
    let object = {};
    if (components) {
        components.forEach((component, i) => {
            components[i] = component.trim();
        });

        //need to check if country is included and strip it out
        components = components.filter(component => {
            return component.toLowerCase() !== "usa";
        });


        const stateZip = components[components.length - 1].split(" ");
        if (stateZip) {
            const reg = new RegExp('^[0-9]+$');
            if (stateZip.length === 2) {
                if (reg.test(stateZip[1])) {
                    object.state = stateZip[0];
                    object.zip = stateZip[1];
                    if (components.length > 1) {
                        object.city = components[components.length - 2];
                        if (components.length > 2) {
                            object.street = components[components.length - 3];
                        }
                    }
                }
            } else if (stateZip.length === 1) {
                if (reg.test(stateZip[0])) {
                    object.zip = stateZip[0];
                } else {
                    object.state = stateZip[0];
                }
                if (components.length > 1) {
                    object.city = components[components.length - 2];
                    if (components.length > 2) {
                        object.street = components[components.length - 3];
                    }
                }
            }
        }
    }
    return object;
};
/***********************************************
 *
 *  Takes a javascript date sets it to midnight and returns it
 ***********************************************/
const AM_setDateToMidnight = (d) => {
    d.setHours(0, 0, 0, 0);
    return d;
}

/***********************************************
 *
 *  Checks if 2 arrays are equal (have the same value AND are in the same order, values should be primitive: strings, numbers, etc.)
 ***********************************************/
const AM_arraysAreEqual = (a, b) => {
    return Array.isArray(a) && Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

/***********************************************
 *
 *  Takes a hex color and returns a lightened or darkened version
 *  For example:
 *  lightenDarkenColor( -0.8, "#c0c0c0") - darkens a color
 *  lightenDarkenColor( 0.4, "#c0c0c0") - brightens a color
 *
 ***********************************************/
const AM_lightenDarkenColor = (p, c0, c1, l) => {

    let r, g, b, P, f, t, h;
    let i = parseInt;
    let m = Math.round;
    let a = typeof (c1) == "string";
    if (typeof (p) != "number" || p < -1 || p > 1 || typeof (c0) != "string" || (c0[0] !== 'r' && c0[0] !== '#') || (c1 && !a)) {
        return null;
    }
    h = c0.length > 9;
    h = a ? c1.length > 9 ? true : c1 === "c" ? !h : false : h;
    f = AM_lightenDarkenColorPSBCr(c0);
    P = p < 0;
    t = c1 && c1 !== "c" ? AM_lightenDarkenColorPSBCr(c1) : P ? {r: 0, g: 0, b: 0, a: -1} : {
        r: 255,
        g: 255,
        b: 255,
        a: -1
    };
    p = P ? p * -1 : p;
    P = 1 - p;
    if (!f || !t) {
        return null;
    }
    if (l) {
        r = m(P * f.r + p * t.r);
        g = m(P * f.g + p * t.g);
        b = m(P * f.b + p * t.b);
    } else {
        r = m(Math.pow((P * Math.pow(f.r, 2) + p * Math.pow(t.r, 2)), 0.5));
        g = m(Math.pow((P * Math.pow(f.g, 2) + p * Math.pow(t.g, 2)), 0.5));
        b = m(Math.pow((P * Math.pow(f.b, 2) + p * Math.pow(t.b, 2)), 0.5));
        a = f.a;
        t = t.a;
        f = a >= 0 || t >= 0;
        a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;
    }
    if (h) {
        return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")";
    } else {
        return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2);
    }
}

/***********************************************
 *
 *  Internal function for AM_lightenDarkenColor
 *  This code came from Stack
 *  https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
 *
 ***********************************************/
const AM_lightenDarkenColorPSBCr =  (d) => {
    let r, g, b;
    let i = parseInt;
    let m = Math.round;
    let a = typeof (c1) == "string";
    let n = d.length;
    let x = {};
    if (n > 9) {
        [r, g, b, a] = d = d.split(",");
        n = d.length;
        if (n < 3 || n > 4) {
            return null;
        }
        x.r = i(r[3] === "a" ? r.slice(5) : r.slice(4));
        x.g = i(g);
        x.b = i(b);
        x.a = a ? parseFloat(a) : -1
    } else {
        if (n === 8 || n === 6 || n < 4) {
            return null;
        }
        if (n < 6) {
            d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
        }
        d = i(d.slice(1), 16);
        if (n === 9 || n === 5) {
            x.r = d >> 24 & 255;
            x.g = d >> 16 & 255;
            x.b = d >> 8 & 255;
            x.a = m((d & 255) / 0.255) / 1000;
        } else {
            x.r = d >> 16;
            x.g = d >> 8 & 255;
            x.b = d & 255;
            x.a = -1;
        }
    }
    return x
}
/***********************************************
 *
 *  Encodes characters for XML
 *
 ***********************************************/
function AM_encodeXmlSpecialChars(txt) {
    return txt
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;");
}

/***********************************************
 *
 * Parses the USPS XML resposne and returns an address object
 *
 ***********************************************/
function AM_parseUSPSAddress(response) {
    const errorObject = {status: WEB_SERVICES_QUMMUTE_ERROR, description: "Invalid address"};

    // var status = {code:WEB_SERVICES_STATUS_OK, description:"OK"};
    if (response && response.data && response.data.length){
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response.data,"text/xml");
        const address = xmlDoc.getElementsByTagName("Address");

        if (address.length > 0) {
            const error = xmlDoc.getElementsByTagName("Error");
            if (error.length > 0) {
                return errorObject;
            } else {
                return {
                    address1: AM_getXMLTagContent(xmlDoc, "Address2"),
                    address2: AM_getXMLTagContent(xmlDoc, "Address1"),
                    city: AM_getXMLTagContent(xmlDoc, "City"),
                    state: AM_getXMLTagContent(xmlDoc, "State"),
                    zip: AM_getXMLTagContent(xmlDoc, "Zip5"),
                    status: WEB_SERVICES_STATUS_OK
                };
            }
        } else {
            return errorObject;
        }
    } else{
        return errorObject;
    }
}
/***********************************************
 *
 * Gets the content from an XML tag
 *
 ***********************************************/
function AM_getXMLTagContent(xmlDoc, tagName) {
    const elements = xmlDoc.getElementsByTagName(tagName);

    if (elements && elements.length) {
        const element = elements[0];
        if (element && element.childNodes && element.childNodes.length) {
            const node = element.childNodes[0];
            if (node && node.nodeValue) return node.nodeValue;
        }
    }
    return "";
}

/***********************************************
 *
 * Checks if two string values are equal to eachother, checking for null first
 *
 ***********************************************/
function AM_equalIfNullNoCase(s1,s2){
    if (s1 == null && s2 == null){
        return true;
    }
    else if (s1 == null || s2 == null){
        return false;
    }
    else {
        return s1.toLowerCase() === s2.toLowerCase();
    }
}
/***********************************************
 *
 * Opens a new tab with epa info for a vehicle
 *
 ***********************************************/
function AM_viewEpaData(id) {
    let url = window.innerWidth < 768 ? EPA_VEHICLE_URL_MOBILE_SITE : EPA_VEHICLE_URL_FULL_SITE;
    window.open(url + id, "_blank");
}

/***********************************************
 *
 * Checks if an element is within the viewport
 *
 ***********************************************/
function AM_elementIsVisibleInViewport(el, partiallyVisible) {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
        ? ((top > 0 && top < innerHeight) ||
            (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
        : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
}

/***********************************************
 *
 * Scrolls a form element into view
 *
 ***********************************************/
function AM_scrollElementIntoView(ref) {
    ref.scrollIntoView({behavior: 'smooth',
        block: 'center',
        inline: 'center'});
}

/***********************************************
 *
 * Scrolls an element to top of window
 *
 ***********************************************/
function AM_scrollToTopOfElement(ref, landingPage, mobile) {
    //header is 70 but we added 5 so there's a little space between header and top of element we scrolled to
    const headerHeight = landingPage ? mobile ? 86 : 112 : 75;
    const topPosition = ref.getBoundingClientRect().top + window.pageYOffset - headerHeight;
    //scroll to section
    window.scrollTo({top: topPosition, behavior: 'smooth'});
}

function AM_mergeHomeWorkLocations(location, userLocations) {
    let locationsCopy = userLocations.filter(loc => loc.mode !== location.mode);
    locationsCopy.push(location);
    return locationsCopy;
}

/***********************************************
 *
 * Checks if a location is complete
 *
 ***********************************************/
function AM_locationIncomplete(location) {
    if (location) return !location.city || !location.state || !location.street || !location.zip || !location.lat || !location.lng;
    return true;
}

/***********************************************
 *
 * Calculate race progress
 *
 ***********************************************/
function AM_calculateRaceProgress(rank, totalIndividuals) {
    if (totalIndividuals > 1) {
        if (totalIndividuals === rank) {
            //user is in last place, so return 1 so we have some bar to show
            return 1;
        } else {
            return ((totalIndividuals - rank) / (totalIndividuals - 1)) * 100;
        }
    } else {
        return 100;
    }
}
/***********************************************
 *
 * Gets the distance (as the crow flies) between 2 coordinates in miles
 *
 ***********************************************/
function AM_distanceBetweenCoords(lat1, lon1, lat2, lon2) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    } else {
        let radlat1 = Math.PI * lat1/180;
        let radlat2 = Math.PI * lat2/180;
        let theta = lon1-lon2;
        let radtheta = Math.PI * theta/180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        return dist;
    }
}
/***********************************************
 *
 * Removes the protocol from a URL, (HTTP, HTTPS, etc.)
 *
 ***********************************************/
function AM_removeURLProtocol(url) {
    if (url && url.length) {
        url = url.replace(/^http:\/\//, "");
        url = url.replace(/^https:\/\//, "");
        url = url.replace(/^ftp:\/\//, "");
        url = url.replace(/^mailto:\/\//, "");
    }
    return url;
}

function AM_convertProfileInfotoUserInfo(profileInfo, existingLocations, needsVerification) {

    let locs = AM_mergeHomeWorkLocations(profileInfo.home, existingLocations);
    if (profileInfo.organization) {
        locs = AM_mergeHomeWorkLocations(profileInfo.organization, locs);
    } else {
        locs = locs.filter(loc => loc.mode !== 2);
    }

    return {
        account_setup: true,
        commute_address: profileInfo.organization ? AM_addressString(profileInfo.organization) : "",
        commute_lat: profileInfo.organization ? profileInfo.organization.lat : 0,
        commute_lng: profileInfo.organization ? profileInfo.organization.lng : 0,
        first_name: profileInfo.firstName,
        gender: profileInfo.gender,
        home_address: true,
        last_name: profileInfo.lastName,
        live_address: AM_addressString(profileInfo.home),
        live_lat: profileInfo.home.lat,
        live_lng: profileInfo.home.lng,
        locations: locs,
        mode_reselect_required: false,
        org_affiliation_id: profileInfo.organization ? profileInfo.organization.affiliationId : -1,
        org_subgroup_id: profileInfo.organization ? profileInfo.organization.subgroupId : 0,
        org_id: profileInfo.organization ? profileInfo.organization.orgId : -1,
        profile_expired: false,
        username: profileInfo.nuriderName,
        verify_email: needsVerification
    }
}

function AM_checkAddressesDifferent(suggested, original) {
    return (!AM_equalIfNullNoCase(suggested.address1 , original.address1) ||
        !AM_equalIfNullNoCase(suggested.address2 , original.address2) ||
        !AM_equalIfNullNoCase(suggested.city ,     original.city) ||
        !AM_equalIfNullNoCase(suggested.state ,    original.state) ||
        !AM_equalIfNullNoCase(suggested.zip,  original.zip));
}

function AM_supportsLocalStorage() {
    try {
        const key = `__storage__test`;
        window.localStorage.setItem(key, null);
        window.localStorage.removeItem(key);
        return true;
    } catch (e) {
        return false;
    }
}

/***********************************************
 *
 * This converts a minute value to a date to be used in timepickers
 *
 * ie. 360 minutes = date with time of 6AM
 ***********************************************/
function AM_convertMinutesToDate(minutes) {
    let date = new Date();
    date.setHours(Math.floor(minutes / 60), minutes % 60);
    return date;
}

/***********************************************
 *
 * This converts a date to a minute total for saving in web services
 *
 * ie. A date with time of 6AM will be converted to 360
 ***********************************************/
function AM_getMinutesFromDate(date) {
    return date.getHours() * 60 + date.getMinutes();
}
/***********************************************
 *
 * Adjusts a date to the nearest 15 minute increment
 *
 ***********************************************/
function AM_adjustDateToNearest15MinIncrement(date) {
    if (date) {
        const offset = date.getMinutes() % 15;
        if (offset !== 0) {
            if (offset < 8) {
                date = new Date(date.getTime() - (offset * MILLISECONDS_PER_MINUTE));
            } else {
                date = new Date(date.getTime() + ((15 - offset) * MILLISECONDS_PER_MINUTE));
            }
        }
    }
    return date;
}

/***********************************************
 *
 *  Converts the first letter in a string to lower case (ie. "Walk to New Haven" --> "walk to New Haven")
 *
 *  Input: str = string
 *
 *  Returns string with first letter lower case
 *
 *  @return {string}
 ***********************************************/
function AM_toFirstLetterLowerCase(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}
/***********************************************
 *
 *  Returns message for failed login
 *
 ***********************************************/
function AM_getLoginFailureMessage(error, email, pwd, token) {

    let msg = "There was a login failure from the web app:<br/> ";
    msg += "<br/>Date: " + new Date();
    msg += `<br/>Email: ${email ? email : ""}`;
    msg += `<br/>Password: ${pwd ? pwd : ""}`;
    msg += `<br/>Token: ${token ? token : ""}`;
    msg += "<br/>Error code: " + error;

    try {
        msg += "<br/>System: " + JSON.stringify(AM_getSystemAttributes());
    } catch (e) {
        msg += "<br/>System: Error loading info";
    }

    return msg;
}

const AM_generateMapMarker = ({map, maps, pinImg, iconWidth, lat, lng, name, isDraggable}) => {
    const mapIcon = document.createElement("img");
    mapIcon.setAttribute("src", pinImg);
    mapIcon.setAttribute("width", iconWidth);

    return new maps.marker.AdvancedMarkerElement({
        position: {
            lat: lat,
            lng: lng,
        },
        map: map,
        content: mapIcon,
        title: name ?? "",
        gmpDraggable: isDraggable,
    })
}

export {AM_getBrandLogos, AM_getURLParameter, AM_randomizeArray, AM_testPassword, AM_createHTMLObject, AM_getSystemAttributes, AM_isObjectEmpty,
    AM_convertHex, AM_createPhotoArray, AM_ensureHTTP, AM_openLink, AM_iOS, AM_Android, AM_addMinutesToDate, AM_previousSunday,
    AM_nextSunday, AM_nextSaturday, AM_validURL, AM_prepareLocationForSaving, AM_tripIcon, AM_tripThemeText, AM_datesOnSameDay,
    AM_dateWithinRecordableRange, AM_isMobile, AM_getIconEventMetric, AM_toTitleCase, AM_getThemeTextEventMetric, AM_getPartnerStatusText,
    AM_parseLocationAddressComponents, AM_setDateToMidnight, AM_arraysAreEqual, AM_lightenDarkenColor, AM_extractAddressComponentsFromString,
    AM_encodeXmlSpecialChars, AM_parseUSPSAddress, AM_equalIfNullNoCase, AM_viewEpaData, AM_scrollElementIntoView, AM_mergeHomeWorkLocations,
    AM_locationIncomplete, AM_calculateRaceProgress, AM_distanceBetweenCoords, AM_removeURLProtocol, AM_convertProfileInfotoUserInfo, AM_scrollToTopOfElement,
    AM_checkAddressesDifferent, AM_supportsLocalStorage, AM_convertMinutesToDate, AM_getMinutesFromDate, AM_adjustDateToNearest15MinIncrement,
    AM_tripModeTextAbbreviatedKey, AM_toFirstLetterLowerCase, AM_getLoginFailureMessage, AM_elementIsVisibleInViewport, AM_tripModeTextKey, AM_generateMapMarker}