// @flow
import React, {Suspense, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container} from 'react-bootstrap';
import * as api from '../scripts/api';

// actions
import {changeCobrand, showGenericModal, updateSpecialEvents} from '../redux/actions';

import * as layoutConstants from '../constants/layout';

// components
import {
    addAboutUsToMenu,
    addAdminMenuItems,
    addAutoRecordedTrips,
    addCobrandMenuItemOne,
    addCobrandMenuItemTwo, addERHToMenu,
    addSpecialEventToMenu,
    removeAboutUs,
    removeAdminMenuItems,
    removeAutoRecordedTrips,
    removeCobrandMenuItemOne,
    removeCobrandMenuItemTwo, removeERHFromMenu,
    removeSpecialEventsFromMenu
} from "../constants/menu2";
import {AM_isUserAuthenticated} from "../scripts/api";

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('./Topbar'));
const LeftSidebar = React.lazy(() => import('./LeftSidebar'));
const Footer = React.lazy(() => import('./Footer'));

const loading = () => <div className=""><p></p></div>;

//mock function to get the user's dark mode setting.
// We would get this either from the server via some user saved setting, the operating system setting, OR a previously saved variable to local storage.
const getThemeSettingFromStorage = () => 'light';

export default function VerticalLayout({children}, state) {
    const dispatch = useDispatch();

    const {theme, leftSideBarType, user, brand, brandWebsite, aboutUs, coBrand} = useSelector((state) => ({
        theme: state.Layout.theme,
        layoutWidth: state.Layout.layoutWidth,
        leftSideBarType: state.Layout.leftSideBarType,
        showRightSidebar: state.Layout.showRightSidebar,
        user: state.Auth.user,
        brand: state.Brand,
        brandWebsite: state.Brand.website,
        aboutUs: state.Brand.aboutUs,
        coBrand: state.Cobrand.data,
    }));

    const [isMenuOpened, setIsMenuOpened] = useState(false)
    const [dummyState, setDummyState] = useState(false);

    const userAuthenticated = AM_isUserAuthenticated();

    /**
     * Open the menu when having mobile screen
     */
    function openMenu() {
        if (window.innerWidth < 992) {
            setIsMenuOpened((prevState) => {
                setIsMenuOpened(!prevState);
            });

            if (document.body) {
                if (isMenuOpened) {
                    document.body.classList.remove('sidebar-enable');
                } else {
                    document.body.classList.add('sidebar-enable');
                }
            }
        }
    }

    function checkForERH() {
        if (brand.erhgrh_name?.length) {
            addERHToMenu(brand.erhgrh_name, brand.erhgrh_id);
        }
    }

    function checkForAdmin() {
        if (user.admin_features && user.admin_features.length > 0) {
            addAdminMenuItems(user.admin_features);
        }
    }

    useEffect(() => {
        if (user && user.member_id) {
            const specialEventUrl = `/public/v2/special_events?member=${user.member_id}`;



            if (brand.trip_tracking || user.beta_tester) {
                addAutoRecordedTrips();
            }

            if (brandWebsite && aboutUs) {
                addAboutUsToMenu(brandWebsite);
            }

            // Get special events
            api.AM_webServiceGet(specialEventUrl).then((response) => {
                // Sort events based on priority
                if (response.data) {
                    response.data.sort((a, b) => {
                        if (a.priority < b.priority) {
                            return -1;
                        } else if (a.priority > b.priority) {
                            return 1;
                        } else {
                            return 0;
                        }
                    })

                    dispatch(updateSpecialEvents(response.data));

                    response.data.forEach(specialEvent => {
                        addSpecialEventToMenu(specialEvent.name, specialEvent.id);
                    });
                }

                checkForERH();
                checkForAdmin();
                //this is some un-used state variable we use here to trigger a re-render of the menu
                //this is to make sure the event menu items are added properly
                setDummyState(true);
            }).catch(() => {
                dispatch(showGenericModal());
                checkForERH();
            })
        }

        return () => {
            removeSpecialEventsFromMenu();
            removeAdminMenuItems();
            removeAutoRecordedTrips();
            removeAboutUs();
            removeERHFromMenu();
        }
    }, [user?.member_id]);

    useEffect(() => {
        if (userAuthenticated) {
            // Update cobranding
            const cobrandUrl = '/member/cobrand';
            api.AM_webServiceGet(cobrandUrl).then((response) => {
                dispatch(changeCobrand(response.data))
            }).catch((e) => {
                console.log(e);
            });
        }
    }, [user?.commute_address, user?.live_address, userAuthenticated]);

    useEffect(() => {
        // Update cobranding in menu bar
        if (coBrand?.id1 && coBrand?.name1) {
            removeCobrandMenuItemOne();
            addCobrandMenuItemOne(coBrand.name1, coBrand.id1);
        }

        if (coBrand?.id2 && coBrand?.name2) {
            removeCobrandMenuItemTwo();
            addCobrandMenuItemTwo(coBrand.name2, coBrand.id2);
        }

        return (() => {
            removeCobrandMenuItemOne();
            removeCobrandMenuItemTwo();
        })
    }, [coBrand?.id1, coBrand?.id2])


    const isCondensed = leftSideBarType === layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED;
    const isLight = theme === layoutConstants.THEME_LIGHT;

    if (!userAuthenticated) {
        return <Suspense fallback={loading()}>{children}</Suspense>;
    } else {
        return (
            <div className="wrapper">
                <Suspense fallback={loading()}>
                    <LeftSidebar openLeftMenuCallBack={openMenu} isCondensed={isCondensed} isLight={isLight}
                                 hideUserProfile={true}/>
                </Suspense>
                <div className="content-page">
                    <div className="content">
                        <Suspense fallback={loading()}>
                            <Topbar openLeftMenuCallBack={openMenu} hideLogo={true} user={true}/>
                        </Suspense>
                        <Container fluid>
                            <Suspense fallback={loading()}>{children}</Suspense>
                        </Container>
                    </div>
                    <Suspense fallback={loading()}>
                        <Footer footerCssClasses="footer" user={true}/>
                    </Suspense>
                </div>
            </div>
        );
    }
}