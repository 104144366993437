// @flow
import React, { useEffect, Suspense } from 'react';
import {changeTheme} from "../redux/layout/actions";
import {useDispatch} from "react-redux";
import {DEFAULT_THEME} from "../constants";

const loading = () => <div className=""><p></p></div>;

type DefaultLayoutProps = {
    layout: {
        layoutType: string,
        layoutWidth: string,
        leftSideBarTheme: string,
        leftSideBarType: string,
        showRightSidebar: boolean,
    },
    user: any,
    children?: any,
};

const DefaultLayout = (props: DefaultLayoutProps) => {

    const dispatch = useDispatch();

    useEffect(() => {
        // if (document.body) document.body.classList.add('authentication-bg');
        // if (document.body) document.body.removeAttribute('data-theme');
        dispatch(changeTheme(DEFAULT_THEME));

        return () => {
            if (document.body) document.body.classList.remove('authentication-bg');
        };
    }, []);


    // get the child view which we would like to render
    const children = props.children || null;

    return (
        <Suspense fallback={loading()}>{children}</Suspense>
    );
};
export default DefaultLayout;
