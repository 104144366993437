import React, {useEffect} from 'react';
import Routes from './routes/Routes';
import BrandSelector from "./branding/brandconfig";

// Navigation
import {setGlobalFunctions} from './scripts/navigation';

// CSS
import './assets/scss/Creative.scss';

// Generic error modal
import GenericModal from "./components/GenericModal";
// import {configureFakeBackend} from "./helpers/fake-backend";

/*
 * Main app component
 */
export default function App() {
    useEffect(() => {
        // Set global navigation function
        setGlobalFunctions();

        // Fake backend
        // configureFakeBackend();
    }, [])

    return (
        <>
            <BrandSelector>
                <Routes />
            </BrandSelector>
            <GenericModal/>
        </>
    );
};
